.section {
  display: flex;
  column-gap: var(--padding-m);
}

.link {
  color: var(--color-blue-100);
}

.link:hover {
  color: var(--color-white);
}

.icon {
  flex-shrink: 0;
}

.successIcon path {
  stroke: var(--color-white);
}

.notificationOffIcon path {
  stroke: var(--color-additionaly-bitter-sweet);
}
