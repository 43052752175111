:root {
  --dark-blue: var(--color-blue-900);
  --main-blue: var(--color-blue-500);
  --lightest-blue: var(--color-blue-100);
  --secondary-green: #28aa5c;
  --main-dark-gray: var(--color-gray-900);
  --secondary-dark-gray: var(--color-gray-800);
  --secondary-middle-gray: var(--color-gray-700);
  --color-disabled: var(--color-gray-600);
  --divider-color: var(--color-gray-300);
  --hover-color: var(--color-gray-200);
  --bg-light-gray: var(--color-gray-100);
  --bg-blue: var(--color-blue-200);
  --bg-middle-blue: var(--color-blue-100);
  --bg-light-blue: var(--color-blue-50);
  --bg-msg-color: var(--bg-light-blue);
  --danger-color: #dd1e2a;
  --bg-content: #fff;
}
