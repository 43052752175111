.Badge {
}

.Badge-type-online {
  color: var(--secondary-green);
  border: 3px solid var(--bg-content);
  background-color: var(--secondary-green);
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: relative;
}

.Badge-type-notification {
  color: var(--bg-content);
  background-color: var(--main-blue);
  font-size: var(--extra-small-text-size);
  line-height: var(--l5-line-height);
  font-weight: var(--text-bold);
  border-radius: 15px;
  width: var(--notification-badge-size);
  height: var(--notification-badge-size);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Badge-type-notification.disabled {
  background-color: var(--color-disabled);
}
