.cw-message__outer {
  display: flex;
}

.cw-message__inner {
  flex: 1;
  display: flex;
  position: relative;
}

.cw-message__actions {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 var(--padding-xs);
}

.cw-message__bubble {
  min-width: 134px;
  max-width: 460px;
  overflow-wrap: break-word;
  background-color: #ebf0ff;
  padding: 0 var(--padding-m) var(--padding-xs);
  position: relative;
}

.cw-message__spacer {
  flex: 1;
}

.cw-message-actions__status {
  position: absolute;
  bottom: 8px;
}

.cw-message--right .cw-message__avatar {
  padding-right: 0;
  padding-left: var(--padding-xs);
}

.cw-message--left .cw-message__avatar {
  padding-right: var(--padding-xs);
  padding-left: 0;
  overflow: visible;
}

.cw-message--right .cw-message__inner {
  flex-direction: row-reverse;
}

.cw-message--right .cw-message__bubble {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.cw-message--left .cw-message__bubble {
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.cw-message.select-mode {
  position: relative;
  cursor: pointer;
}

.cw-message__select-icon {
  display: none;
  position: absolute;
  left: 24px;
  top: 18px;
}

.cw-message.select-mode .cw-message__select-icon {
  display: block;
}

.cw-message.selected {
  background: rgb(235 240 255 / 50%);
  border-radius: 4px;
}

.cw-message--bg-color-gray-1 .cw-message__bubble {
  background-color: #f2f4f8;
}

.cw-message--bg-color-gray-2 .cw-message__bubble {
  background-color: #ebf0ff;
}

.cw-message-actions__menu {
  opacity: 0;
}

.cw-message__inner:hover .cwm-um-footer__btn,
.cw-message__inner:hover .cwm-msg__for-extra,
.cw-message.active .cwm-um-footer__btn,
.cw-message.active .cwm-msg__for-extra {
  opacity: 1;
}

.cw-message__inner:hover .cw-message-actions__menu,
.cw-message.active .cw-message-actions__menu {
  opacity: 1;
}

.cw-message__inner:hover .cw-message-actions__status,
.cw-message.active .cw-message-actions__status {
  opacity: 0;
}

@media (min-width: 1330px) {
  .layout-simple__content-short .cw-message--right .cw-message__inner {
    flex-direction: row;
  }

  .cw-message--right .cw-message__avatar {
    padding-left: var(--padding-xs);
    padding-right: 0;
  }
}

@media (min-width: 1494px) {
  .layout-simple__content-full .cw-message--right .cw-message__inner {
    flex-direction: row;
  }

  .cw-message--right .cw-message__avatar {
    padding-right: var(--padding-xs);
    padding-left: 0;
  }
}

.cw-message.blink .cw-message__bubble {
  animation: blinking 1.4s 1 linear;
}
