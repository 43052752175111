.video-chat-sidebar-wrapper {
  position: relative;
  width: 0;
  height: calc(100% - var(--video-chat-control-panel-height));
  transform: translateX(var(--video-chat-sidebar-width));
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  flex-shrink: 0;
}

.video-chat-sidebar-wrapper_opened {
  transform: translateX(0);
  width: var(--video-chat-sidebar-width);
}

.video-chat-sidebar {
  position: absolute;
  top: var(--padding-m);
  right: var(--padding-m);
  bottom: var(--padding-m);
  left: var(--padding-m);
  background-color: #fff;
  border-radius: 8px;
}

.video-chat-sidebar__content {
  height: 100%;
}
