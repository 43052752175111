:root {
  --color-blue-900: #0c2362;
  --color-blue-800: #113187;
  --color-blue-700: #1a3fa3;
  --color-blue-600: #1848c9;
  --color-blue-500: #1c50de;
  --color-blue-400: #466fe0;
  --color-blue-300: #658aef;
  --color-blue-200: #a7beff;
  --color-blue-100: #d4e0ff;
  --color-blue-50: #f3f6ff;
  --color-gray-900: #212229;
  --color-gray-800: #5b5f73;
  --color-gray-700: #7f869e;
  --color-gray-600: #9da2b4;
  --color-gray-500: #b9bfcf;
  --color-gray-400: #dce1ee;
  --color-gray-300: #ebf0f9;
  --color-gray-200: #f6f7fa;
  --color-gray-100: #fafafc;
  --color-gray-400-hover-element: #dce1ee;
  --color-white: #fff;
  --color-black: #000;
  --color-border-chat: #ebeef4;
  --color-red-error: #dd1e2a;
  --color-second-green: #2ba23e;
  --color-additionaly-orange: #f3753f;
  --color-additionaly-grass: #35a153;
  --color-additionaly-lavender: #9182e2;
  --color-additionaly-cardinal: #bc2041;
  --color-additional-lochinvar: #399;
}
