:root {
  --h1-text-size: 18px;
  --h2-text-size: 16px;
  --main-text-size: 14px;
  --small-text-size: 12px;
  --extra-small-text-size: 10px;
  --x-extra-small-text-size: 9px;
  --text-bold: 600;
  --text-semibold: 500;
  --text-regular: 400;
  --h1-line-height: 22px;
  --h2-line-height: 20px;
  --h3-line-height: 18px;
  --l1-line-height: 18px;
  --l2-line-height: 17px;
  --l3-line-height: 15px;
  --l4-line-height: 15px;
  --l5-line-height: 12px;
  --l6-line-height: 11px;
}
