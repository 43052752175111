@keyframes icon-hover {
  0% {
    r: 0%;
  }

  50% {
    r: 25%;
  }

  100% {
    r: 50%;
  }
}

@keyframes blinking {
  0% {
    box-shadow: 0 0 0 3px rgb(28 80 222 / 80%) inset;
  }

  100% {
    box-shadow: 0 0 0 0 transparent inset;
  }
}
