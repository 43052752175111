.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--indent-xs);
}

.icon {
  flex-shrink: 0;
}

.text {
  display: flex;
  align-items: baseline;
}

.userName {
  display: inline;
}
