@import '../../../themeProvider/breakpoints.module';

.container {
  display: flex;
  flex-direction: column;
  row-gap: var(--indent-md);

  @include media-lg {
    display: none;
  }
}
