$breakpoint-xs: 360;
$breakpoint-sm: 600;
$breakpoint-md: 900;
$breakpoint-lg: 1200;
$breakpoint-xl: 1400;
$breakpoint-xxl: 1900;
$var-breakpoint-xs: 360px;
$var-breakpoint-sm: 600px;
$var-breakpoint-md: 900px;
$var-breakpoint-lg: 1200px;
$var-breakpoint-xl: 1400px;
$var-breakpoint-xxl: 1900px;

@mixin media-xs {
  @media (width < $var-breakpoint-xs) {
    @content;
  }
}
@mixin media-sm {
  @media (width < $var-breakpoint-sm) {
    @content;
  }
}
@mixin media-md {
  @media (width < $var-breakpoint-md) {
    @content;
  }
}
@mixin media-lg {
  @media (width < $var-breakpoint-lg) {
    @content;
  }
}
@mixin media-xl {
  @media (width < $var-breakpoint-xl) {
    @content;
  }
}

@mixin media-xxl {
  @media (width < $var-breakpoint-xxl) {
    @content;
  }
}

:export {
  breakpointXs: $breakpoint-xs;
  breakpointSm: $breakpoint-sm;
  breakpointMd: $breakpoint-md;
  breakpointLg: $breakpoint-lg;
  breakpointXl: $breakpoint-xl;
  breakpointXxl: $breakpoint-xxl;
}
