.Avatar {
  flex-shrink: 0;
  position: relative;
  background-size: cover;
}

.Avatar-failed .Avatar__img {
  visibility: hidden;
}

.Avatar__img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
}

.Avatar__badge {
  position: absolute;
  z-index: 1;
  bottom: min(var(--max-online-badge-size), var(--online-badge-size));
  right: min(var(--max-online-badge-size), var(--online-badge-size));
  transform: translate(100%, 100%);
}

.Avatar__children {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
