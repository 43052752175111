.cws-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.cws-avatar_default {
  background-color: white;
  border: 1px solid #ebeef4;
  padding: 2px;
  flex-shrink: 0;
}

.cws-avatar_blue {
  background-color: #1c50de;
}

.cws-avatar_cursor_pointer {
  cursor: pointer;
}

.cws-avatar_cursor_default {
  cursor: default;
}

.cws-avatar_status_offline {
  border-color: #ebeef4;
}

.cws-avatar_status_online {
  border-color: #37b450;
}

.cws-avatar__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.cws-avatar__img-color {
  /* width: 100%; */

  /* height: 100%; */
  border-radius: 50%;
}

.cws-avatar__img-color img {
  object-fit: none;
}

.cws-avatar__img-color_blue {
  background-color: #1c50de;
}

.cws-avatar__color-icon,
.cws-avatar__color-char {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  color: white;
  line-height: 1.1;
}

.cws-avatar__color-icon {
  background-position: center;
  background-repeat: no-repeat;
}

.cws-avatar__color-icon_type_response {
  background-image: url('../../../img/chat-widget/response.svg');
  background-size: 18px 18px;
}
