.signal {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAAASCAYAAADbo8kDAAAAAXNSR0IArs4c6QAAA7hJREFUWEftWEtLVVEYXYtoUJS9BhkUZIMmESk0CXpZ0yCbB+ovyH5B9QuyX5BOmmbQNJMgHDTICBs4KCMHCkkPIhsIK9Zl78u+x/M0FSE3yLn3nn3W2Xt961vftyV2xpYwwC15yzpfIqkHwBLJ32UQkvYCOEryU968DcS5A+AZyfmK9ZwEMEByNM7btkRLOg3AfzMkFyo2dhxAL4CPJD+kczcQ5x6A+wCGSI5XrGcIwGMAoyTveu6mEi2pG8DPrCKDAveTXCpQoBV6DcAcybk6OJLOAHAGTJNcNm54T4ozEALXoUhJVmAvyYkCHN93tjwgeV9SHRyr2RnQT3Jq04iWFFXWIiujMhNiYtqkZO6fA3AEwCsADpbVWorjgIbgOLDTgegU53ZQWYuszPtGADw0KQBe5+CMAbgCoM+WUBNnJgTHGdlfSrQkb9Ap43G3ypsyi79uUZGczMORdLmVUqTJ7BiSbgBYIDkjqTZOUKMD/ILkagan5d8kewrW8zbc78vBEYAxksOSmuBY1YPOtEKiJR0E4Jf7+j1ce0j6c+mQdADAJacpgF95OAAOBVVPptaSPPsGwJ8mOAAOB/U7eN6b12CcY2EN9s5nBevxPava2baa4JwK863kzw1xrgb195UR7UkvQ6p43tPoNzWIdtpfsDUAOJuHA+B9nBM9NaR7+qx/qo0T1hXn5z1ra/BYs6/kd89pry1Zf/psXZz4rmLrCIp2mkQ1+9pXU9F7ADjlZwF8C17VgQNgX1QNSftraySKfgfgaxMcAC6i54O3R0UbZ1dYgzsA++2afSXeax9eTHCioocBTDXEcRG1QIsVHTbtibYPk3TLnlml5oSwtgeHqt6Bk3pvxtt3h2L0xa1a6uVVOJJc/E6QfC4pi5N68Jp9Zbw3xbF1OjDjJEckNcFxUAfpUUWcJKeZi0hMl6pHojKjBcz6IJHiVPW2kpz+XS5qAOz3/l6K44MNAAd3OQoig3MxWIaL+mhmPe0eGcCTHBxz4MbA/u2rv1fhxFow5SK6aUSHjHBr9oPkSmZjtpaukj46Bql1AAl9dClOEjx3HCsZv4847f43s560j44HpRQn1qvWASTto0twYvDcQMxvKtEZS2iUGcnBoeNkmJdhFT17PMj8K048gHScDAvWE0+G7Z592xIdFOlUXYwKDb+tCZgkZ0h3xf86NgLHB5uJ9DxRQLQz5CbJR1Fs25rovGKw3pqRxdpqnB2iGxb59QasDtEuIPNNWrsCJf7XOJVE1+rldiZVMvAXVosb5MTwvCsAAAAASUVORK5CYII=') 0 100% no-repeat;
  width: 18px;
  height: 18px;
  animation: run 1.5s steps(5) infinite;
}

@keyframes run {
  from {
    background-position: 0;
  }

  to {
    background-position: -90px;
  }
}
