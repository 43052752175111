.section {
  border-right: 1px solid var(--color-gray-800);
  padding-right: var(--indent-xs);
}

.section:first-child {
  padding-bottom: var(--indent-xs);
}

.section:not(:first-child) {
  border-top: 1px solid var(--color-gray-800);
  padding-top: var(--indent-xs);
}
