:root {
  --transcript-sidebar-header-height: 36px;
}

.transcript-chat {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.transcript-chat__header {
  display: flex;
  height: var(--transcript-sidebar-header-height);
}

.transcript-chat__body {
  display: flex;
  flex-direction: column;
  row-gap: var(--padding-m);
  padding: var(--padding-m);
  overflow: auto;
  height: 100%;
}

.transcript-chat__footer {
  border-top: 1px solid var(--color-gray-700);
}
