.xss svg {
  width: 12px;
  height: 12px;
}

.xs svg {
  width: 14px;
  height: 14px;
}

.s svg {
  width: 16px;
  height: 16px;
}

.m svg {
  width: 18px;
  height: 18px;
}

.l svg {
  width: 22px;
  height: 22px;
}

.xl svg {
  width: 28px;
  height: 28px;
}

.xxl svg {
  width: 34px;
  height: 34px;
}

.xss {
  width: 12px;
  height: 12px;
}

.xs {
  width: 14px;
  height: 14px;
}

.s {
  width: 16px;
  height: 16px;
}

.m {
  width: 18px;
  height: 18px;
}

.l {
  width: 22px;
  height: 22px;
}

.xl {
  width: 28px;
  height: 28px;
}

.xxl {
  width: 34px;
  height: 34px;
}
