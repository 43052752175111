.ButtonMicrophone {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid transparent;
}

.ButtonMicrophone.off {
  background-color: var(--color-red-error);
}

.ButtonMicrophone.guest > svg path {
  stroke: var(--color-white);
}

.ButtonMicrophone.outline {
  border: 1px solid var(--color-gray-500);
}

.ButtonMicrophone.off.outline > svg path {
  stroke: var(--color-white);
}

.ButtonMicrophone.outline > svg path {
  stroke: var(--color-gray-800);
}

.ButtonMicrophone.on.filled {
  background-color: var(--color-gray-800);
}

.ButtonMicrophone.off.filled {
  background-color: var(--color-red-error);
}

.ButtonMicrophone.filled > svg path {
  stroke: var(--color-white);
}

.ButtonMicrophone.on.disabled,
.ButtonMicrophone.off.disabled {
  cursor: default;
  pointer-events: none;
  background-color: var(--color-alpha-gray-100);
  border: none;
}

.ButtonMicrophone.on.disabled > svg path,
.ButtonMicrophone.off.disabled > svg path {
  stroke: var(--color-gray-600);
}
