.cws-tooltip {
  position: relative;
  display: inline-flex;
}

.cws-tooltip__text {
  background: #767b92;
  border-radius: 2px;
  padding: 8px 12px;
  color: white;
  font-size: 12px;
  margin: 0;
  border: 0;
  white-space: nowrap;
  user-select: none;
  pointer-events: none;
  z-index: 1045;
}

.cws-tooltip__text svg {
  /* Fix svg arrow at react-laag */
  bottom: 99% !important;
}

.cws-tooltip__children {
  display: inline-flex;
}
