@import "https://fonts.googleapis.com/css?family=Commissioner:400,500,600,700";
:root {
  --font: "Commissioner", "sans-serif";
}

:root {
  --color-blue-900: #0c2362;
  --color-blue-800: #113187;
  --color-blue-700: #1a3fa3;
  --color-blue-600: #1848c9;
  --color-blue-500: #1c50de;
  --color-blue-400: #466fe0;
  --color-blue-300: #658aef;
  --color-blue-200: #a7beff;
  --color-blue-100: #d4e0ff;
  --color-blue-50: #f3f6ff;
  --color-gray-900: #212229;
  --color-gray-800: #5b5f73;
  --color-gray-700: #7f869e;
  --color-gray-600: #9da2b4;
  --color-gray-500: #b9bfcf;
  --color-gray-400: #dce1ee;
  --color-gray-300: #ebf0f9;
  --color-gray-200: #f6f7fa;
  --color-gray-100: #fafafc;
  --color-white: #fff;
  --color-black: #000;
  --color-border-chat: #ebeef4;
  --color-red-error: #dd1e2a;
  --color-additionaly-cardinal: #bc2041;
  --color-additionaly-bitter-sweet: #fd7c6e;
  --color-allert: #e9a33b;
  --color-purple-heart: #7c40b8;
  --color-alpha-gray-100: #41455a1a;
  --color-alpha-blue-50: #1116930f;
  --secondary-yellow: #e1b411;
  --color-alpha-blue-100: #0c23621a;
  --color-secondary-green: #2ba13e;
  --color-aplha-dark-gray-900: #41455ae6;
  --color-additional-lemon: #f1d367;
  --color-yellowish-green: #87c159;
  --color-additional-light-turquoise: #2ad2d2;
}

:root {
  --indent-xxxs: 2px;
  --indent-xxs: 4px;
  --indent-xs: 8px;
  --indent-s: 12px;
  --indent-m: 16px;
  --indent-ml: 20px;
  --indent-md: 24px;
  --indent-l: 28px;
  --indent-xl: 32px;
  --indent-xxl: 64px;
  --modal-actions: 50px;
}

:root {
  --max-online-badge-size: 18px;
  --online-badge-size: 35%;
  --notification-badge-size: 18px;
}

:root {
  --h1-text-size: 18px;
  --h2-text-size: 16px;
  --h3-text-size: 14px;
  --main-text-size: 14px;
  --medium-text-size: 13px;
  --small-text-size: 12px;
  --extra-small-text-size: 10px;
  --text-bold: 600;
  --text-semibold: 500;
  --text-regular: 400;
  --h1-line-height: 22px;
  --h2-line-height: 20px;
  --h3-line-height: 18px;
  --l1-line-height: 18px;
  --l2-line-height: 17px;
  --l3-line-height: 15px;
  --l4-line-height: 15px;
  --l5-line-height: 12px;
}

:root {
  --transition-delay: .3s;
  --needs-filter-width: 300px;
  --cw-header-height: 55px;
  --cw--messenger-height: 54px;
  --cw--messenger-buttons-all-width: 237px;
  --cw-header-notification-height: 38px;
}



@keyframes icon-hover {
  0% {
    r: 0%;
  }

  50% {
    r: 25%;
  }

  100% {
    r: 50%;
  }
}

@keyframes blinking {
  0% {
    box-shadow: inset 0 0 0 3px #1c50decc;
  }

  100% {
    box-shadow: inset 0 0 #0000;
  }
}

:root {
  --dark-blue: var(--color-blue-900);
  --main-blue: var(--color-blue-500);
  --lightest-blue: var(--color-blue-100);
  --secondary-green: #28aa5c;
  --main-dark-gray: var(--color-gray-900);
  --secondary-dark-gray: var(--color-gray-800);
  --secondary-middle-gray: var(--color-gray-700);
  --color-disabled: var(--color-gray-600);
  --divider-color: var(--color-gray-300);
  --hover-color: var(--color-gray-200);
  --bg-light-gray: var(--color-gray-100);
  --bg-blue: var(--color-blue-200);
  --bg-middle-blue: var(--color-blue-100);
  --bg-light-blue: var(--color-blue-50);
  --bg-msg-color: var(--bg-light-blue);
  --danger-color: #dd1e2a;
  --bg-content: #fff;
  --button-border-radius: 20px;
  --bg-button-primary: var(--color-blue-500);
  --bg-button-primary-hover: var(--color-blue-600);
  --bg-button-primary-active: var(--color-blue-700);
  --bg-button-primary-disabled: var(--color-gray-600);
  --text-color-button-primary: #fff;
  --text-color-button-primary-hover: #fff;
  --text-color-button-primary-active: #fff;
  --text-color-button-primary-disabled: #fff;
  --border-color-button-outline: var(--color-gray-800);
  --border-color-button-outline-hover: var(--color-blue-600);
  --border-color-button-outline-active: var(--color-blue-700);
  --border-color-button-outline-disabled: var(--color-gray-600);
  --bg-button-outline: #fff;
  --bg-button-outline-hover: #fff;
  --bg-button-outline-active: #fff;
  --bg-button-outline-disabled: #fff;
  --text-color-button-outline: var(--color-gray-800);
  --text-color-button-outline-hover: var(--color-blue-600);
  --text-color-button-outline-active: var(--color-blue-700);
  --text-color-button-outline-disabled: var(--color-gray-600);
  --text-color-button-text: var(--color-gray-800);
  --text-color-button-text-hover: var(--color-blue-600);
  --text-color-button-text-active: var(--color-blue-700);
  --text-color-button-text-disabled: var(--color-gray-600);
  --bg-toast-color: var(--color-aplha-dark-gray-900);
}



.t456ea_tappable {
  cursor: default;
  transition: background-color .15s ease-out;
  position: relative;
}

.t456ea_hasHover, .t456ea_hasActive {
  cursor: pointer;
}

.t456ea_tappable[disabled], .t456ea_tappable[aria-disabled="true"] {
  cursor: default;
}

.t456ea_focusVisible {
  outline: none;
}

.FpES3W_Text {
  font-family: var(--font);
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
  display: block;
}

.FpES3W_Text.FpES3W_inline {
  display: inline-block;
}

.FpES3W_Text.FpES3W_left {
  text-align: left;
}

.FpES3W_Text.FpES3W_right {
  text-align: right;
}

.FpES3W_Text.FpES3W_center {
  text-align: center;
}

.FpES3W_Text.FpES3W_noWrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.FpES3W_Text-l-h1 {
  font-size: var(--h1-text-size);
}

.FpES3W_Text-l-h2 {
  font-size: var(--h2-text-size);
}

.FpES3W_Text-l-h3 {
  font-size: var(--h3-text-size);
}

.FpES3W_Text-lh-h1 {
  line-height: var(--h1-line-height);
}

.FpES3W_Text-lh-h2 {
  line-height: var(--h2-line-height);
}

.FpES3W_Text-lh-h3 {
  line-height: var(--h3-line-height);
}

.FpES3W_Text-l-1 {
  font-size: var(--main-text-size);
}

.FpES3W_Text-l-2 {
  font-size: var(--small-text-size);
}

.FpES3W_Text-l-3 {
  font-size: var(--extra-small-text-size);
}

.FpES3W_Text-l-4 {
  font-size: var(--medium-text-size);
}

.FpES3W_Text-l-5 {
  font-size: var(--x-extra-small-text-size);
}

.FpES3W_Text-w-1 {
  font-weight: var(--text-bold);
}

.FpES3W_Text-w-2 {
  font-weight: var(--text-semibold);
}

.FpES3W_Text-w-3 {
  font-weight: var(--text-regular);
}

.FpES3W_Text-lh-1 {
  line-height: var(--l1-line-height);
}

.FpES3W_Text-lh-2 {
  line-height: var(--l2-line-height);
}

.FpES3W_Text-lh-3 {
  line-height: var(--l3-line-height);
}

.FpES3W_Text-lh-4 {
  line-height: var(--l4-line-height);
}

.FpES3W_Text-lh-5 {
  line-height: var(--l5-line-height);
}

.FpES3W_Text-lh-6 {
  line-height: var(--l6-line-height);
}

._9VN53q_icon {
  height: 100%;
}

.pZtbbW_button {
  box-sizing: border-box;
  border-radius: var(--indent-xs);
  user-select: none;
  max-width: 100%;
  border: none;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: flex;
  position: relative;
}

.pZtbbW_loading {
  cursor: progress;
}

.pZtbbW_stretched {
  width: 100%;
}

.pZtbbW_in {
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: var(--indent-xs);
  min-height: inherit;
  text-align: center;
  box-sizing: border-box;
  display: flex;
}

.pZtbbW_stretched .pZtbbW_in {
  justify-content: center;
}

.pZtbbW_button-size-large .pZtbbW_in {
  padding: var(--indent-xs) var(--indent-m);
  height: 36px;
}

.pZtbbW_button-size-small .pZtbbW_in {
  padding: var(--indent-xxs) var(--indent-s);
  height: 28px;
}

.pZtbbW_button-variant-text .pZtbbW_in {
  height: 20px;
  gap: var(--indent-xxs);
  padding: 0;
}

.pZtbbW_button-size-large.pZtbbW_iconOnly .pZtbbW_in {
  padding: var(--indent-xs) 10px;
  height: 36px;
}

.pZtbbW_button-size-small.pZtbbW_iconOnly .pZtbbW_in {
  padding: var(--indent-xxs) var(--indent-xs);
  height: 28px;
}

.pZtbbW_content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pZtbbW_button-size-large .pZtbbW_content {
  line-height: 120%;
}

.pZtbbW_button-size-small .pZtbbW_content {
  line-height: normal;
}

.pZtbbW_button-size-large .pZtbbW_icon {
  height: calc(100% - 4px);
}

.pZtbbW_button-size-small .pZtbbW_icon {
  height: calc(100% - var(--indent-xs));
}

.pZtbbW_button-mode-primary {
  background-color: var(--color-blue-500);
  color: var(--color-white);
}

.pZtbbW_button-mode-primary svg path {
  stroke: var(--color-white);
}

.pZtbbW_button-mode-primary.pZtbbW_hover {
  background-color: var(--color-blue-400);
  color: var(--color-white);
}

.pZtbbW_button-mode-primary.pZtbbW_active {
  background-color: var(--color-blue-600);
  color: var(--color-white);
}

.pZtbbW_button-mode-secondary {
  background-color: var(--color-alpha-blue-50);
  color: var(--color-gray-900);
}

.pZtbbW_button-mode-secondary svg path {
  stroke: var(--color-gray-900);
}

.pZtbbW_button-mode-secondary.pZtbbW_hover {
  background-color: var(--color-alpha-blue-100);
  color: var(--color-gray-900);
}

.pZtbbW_button-mode-secondary.pZtbbW_active {
  background-color: var(--color-gray-400);
  color: var(--color-gray-900);
}

.pZtbbW_button-mode-text {
  background-color: inherit;
  color: var(--color-gray-900);
}

.pZtbbW_button-mode-text svg path {
  stroke: var(--color-gray-900);
}

.pZtbbW_button-mode-text.pZtbbW_hover {
  background-color: var(--color-alpha-blue-100);
  color: var(--color-gray-900);
}

.pZtbbW_button-mode-text.pZtbbW_active {
  background-color: var(--color-gray-400);
  color: var(--color-gray-900);
}

.pZtbbW_button-variant-text {
  color: var(--color-gray-900);
  background-color: inherit;
}

.pZtbbW_button-variant-text svg path {
  stroke: var(--color-gray-900);
}

.pZtbbW_button-variant-text.pZtbbW_hover {
  background-color: inherit;
  color: var(--color-blue-500);
}

.pZtbbW_button-variant-text.pZtbbW_active {
  background-color: inherit;
  color: var(--color-blue-400);
}

.pZtbbW_button-variant-text[disabled] {
  background-color: inherit;
  color: var(--color-gray-600);
}

.pZtbbW_button-variant-text[disabled] svg path {
  stroke: var(--color-gray-600);
}

.pZtbbW_button-variant-text.pZtbbW_iconOnly .pZtbbW_in {
  height: 20px;
  gap: var(--indent-xxs);
  padding: 0;
}

.pZtbbW_button-variant-text.pZtbbW_button-mode-secondary {
  color: var(--color-blue-500);
  background-color: inherit;
}

.pZtbbW_button-variant-text.pZtbbW_button-mode-secondary svg path {
  stroke: var(--color-blue-500);
}

.pZtbbW_button-variant-text.pZtbbW_button-mode-secondary.pZtbbW_hover {
  background-color: inherit;
  color: var(--color-blue-700);
}

.pZtbbW_button-variant-text.pZtbbW_button-mode-secondary.pZtbbW_active {
  background-color: inherit;
  color: var(--color-blue-500);
}

.pZtbbW_button-variant-text.pZtbbW_button-mode-secondary .pZtbbW_in, .pZtbbW_button-variant-text.pZtbbW_iconOnly.pZtbbW_button-mode-secondary .pZtbbW_in {
  height: 20px;
  gap: var(--indent-xxs);
  padding: 0;
}

.pZtbbW_button-mode-primary[disabled] {
  background-color: var(--color-gray-600);
  color: var(--color-white);
}

.pZtbbW_button-mode-primary[disabled] svg path {
  stroke: var(--color-white);
}

.pZtbbW_button-mode-secondary[disabled] {
  background-color: var(--color-alpha-blue-50);
  color: var(--color-gray-600);
}

.pZtbbW_button-mode-secondary[disabled] svg path {
  stroke: var(--color-gray-600);
}

.pZtbbW_button-mode-text[disabled] {
  color: var(--color-gray-600);
}

.pZtbbW_button-mode-text[disabled] svg path {
  stroke: var(--color-gray-600);
}

.pZtbbW_button-variant-text[disabled].pZtbbW_button-mode-secondary {
  background-color: inherit;
  color: var(--color-blue-200);
}

.pZtbbW_button-variant-text[disabled].pZtbbW_button-mode-secondary svg path {
  stroke: var(--color-blue-200);
}

.TvBMwG_button {
  cursor: pointer;
  background-color: #0000;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.TvBMwG_button svg {
  width: 100%;
  height: 100%;
}

.TvBMwG_large, .TvBMwG_medium {
  border-radius: var(--indent-xxs);
}

.TvBMwG_large {
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  padding: 7px;
}

.TvBMwG_medium {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  padding: 3px;
}

.TvBMwG_small {
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
}

.TvBMwG_extraSmall {
  width: 12px;
  height: 12px;
}

.TvBMwG_large:hover, .TvBMwG_largeActive, .TvBMwG_medium:hover, .TvBMwG_mediumActive {
  background: var(--color-alpha-blue-100);
}

.TvBMwG_large:active, .TvBMwG_medium:active {
  background: var(--color-alpha-blue-50);
}

.TvBMwG_disabled {
  cursor: none;
  stroke: var(--color-gray-600);
  pointer-events: none;
}

.TvBMwG_disabled:hover {
  cursor: default;
  background-color: #0000;
}

.TvBMwG_disabled > svg path {
  stroke: var(--color-gray-600);
}

.TvBMwG_smallActive svg, .TvBMwG_extraSmallActive svg, .TvBMwG_small:hover svg, .TvBMwG_extraSmall:hover svg, .TvBMwG_smallActive svg > path, .TvBMwG_extraSmallActive svg > path, .TvBMwG_small:hover svg > path, .TvBMwG_extraSmall:hover svg > path {
  stroke: var(--color-blue-500);
}

.cYRfxG_container {
  min-width: 530px;
  gap: var(--indent-ml);
  background: var(--color-white);
  border-radius: var(--indent-xs);
  height: auto;
  flex-direction: column;
  padding: 32px;
  font-size: 14px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 24px #1e222e1f, 0 4px 8px #1e222e0a;
}

.cYRfxG_swipeableArea {
  height: 54px;
  padding-top: var(--indent-ml);
  justify-content: center;
  display: flex;
}

.cYRfxG_puller {
  width: 60px;
  height: 7px;
  background-color: #d9d9d9;
  border-radius: 15px;
}

.cYRfxG_header {
  box-sizing: border-box;
  align-items: center;
  column-gap: var(--indent-xs);
  width: 100%;
  display: flex;
}

.cYRfxG_header .cYRfxG_headerText {
  color: var(--color-gray-900);
  max-width: 100%;
  flex: 1;
}

.cYRfxG_header .cYRfxG_closeBtn {
  flex-shrink: 0;
}

.cYRfxG_footer {
  align-items: center;
  column-gap: var(--indent-m);
  row-gap: var(--indent-m);
  flex-wrap: wrap;
  display: flex;
}

.cYRfxG_content {
  box-sizing: border-box;
  width: 100%;
  color: var(--color-gray-900);
  overflow-x: hidden;
  overflow-y: auto;
}

.cYRfxG_content::-webkit-scrollbar {
  width: 8px;
  background-color: var(--color-gray-200);
}

.cYRfxG_content::-webkit-scrollbar-thumb {
  background: #0c236233;
  border-radius: 13px;
}

.cYRfxG_actions {
  align-items: center;
  gap: var(--indent-m);
  display: flex;
}

.cYRfxG_right {
  justify-content: right;
}

.cYRfxG_center {
  justify-content: center;
}

.cYRfxG_left {
  justify-content: left;
}

.cYRfxG_space-between {
  justify-content: space-between;
}

.cYRfxG_drawerContainer {
  background: var(--color-white);
  height: auto;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.cYRfxG_drawerContainer .cYRfxG_header {
  padding: 6px var(--indent-md);
}

.cYRfxG_drawerContainer .cYRfxG_content {
  padding: 0 var(--indent-md);
}

.cYRfxG_drawerContainer .cYRfxG_footer {
  border-top: 1px solid var(--color-gray-400);
  padding: var(--indent-m);
  padding-bottom: 0;
}

.cYRfxG_drawerContainer .cYRfxG_actions {
  width: 100%;
  gap: var(--indent-m);
  padding-bottom: var(--modal-actions);
  grid-auto-flow: column;
  display: grid;
}

.cYRfxG_drawerContent {
  gap: var(--indent-m);
  max-height: inherit;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  position: relative;
  overflow: hidden;
}

.cYRfxG_root .MuiDialog-scrollPaper {
  align-items: flex-start;
}

.cYRfxG_root .MuiDialog-container .MuiPaper-root {
  max-width: 80vw;
  max-height: 80vh;
}

.cYRfxG_paper .MuiDialog-container .MuiPaper-root {
  max-width: 80vw;
  max-height: 92vh;
}

.cYRfxG_drawer {
  border-top-left-radius: var(--indent-xs);
  border-top-right-radius: var(--indent-xs);
  box-shadow: 0 8px 24px #1e222e1f, 0 4px 8px #1e222e0a;
}

@media (min-width: 1280px) {
  .cYRfxG_paper .MuiDialog-container .MuiPaper-root {
    max-width: 100%;
  }
}

._B4dka_loader {
  background-color: var(--bg-content);
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

._B4dka_dots {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

._B4dka_dots._B4dka_medium {
  width: 24px;
  height: 24px;
}

._B4dka_dots._B4dka_large {
  width: 32px;
  height: 32px;
}

._B4dka_dots ._B4dka_dot {
  width: 40%;
  height: 40%;
  border: 0;
  border-radius: 50%;
  margin: 0;
  animation: 2s infinite _B4dka_spin;
  position: absolute;
}

._B4dka_dots ._B4dka_dot:nth-child(1) {
  background: #1c50de;
  animation-delay: -1.5s;
}

._B4dka_dots ._B4dka_dot:nth-child(2) {
  background: #b9bfcf;
  animation-delay: -1s;
}

._B4dka_dots ._B4dka_dot:nth-child(3) {
  background: #a7beff;
  animation-delay: -.5s;
}

._B4dka_dots ._B4dka_dot:nth-child(4) {
  background: #7f869e;
}

@keyframes _B4dka_spin {
  0%, 100% {
    transform: translate(0);
  }

  25% {
    transform: translate(160%);
  }

  50% {
    transform: translate(160%, 160%);
  }

  75% {
    transform: translate(0, 160%);
  }
}

.Rc6TnW_autocomplete {
  width: 100%;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  border-radius: 4px;
}

.Rc6TnW_autocomplete.Rc6TnW_error {
  border: 1px solid var(--color-red-error);
  border-radius: 4px;
}

.Rc6TnW_autocomplete:hover {
  border-color: var(--color-blue-200);
}

.Rc6TnW_autocomplete.Rc6TnW_active {
  border: 1px solid var(--color-blue-400);
  border-radius: 4px;
  box-shadow: 0 0 5px #2369f14f;
}

.Rc6TnW_autocomplete .MuiAutocomplete-root {
  text-transform: none;
  border: none;
  padding: 0;
}

.Rc6TnW_autocomplete .MuiAutocomplete-root .MuiInputBase-root {
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
}

.Rc6TnW_autocomplete .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
  padding-right: 10px;
}

.Rc6TnW_autocomplete .MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  text-transform: none;
  padding: 0;
}

.Rc6TnW_autocomplete .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding: var(--indent-xs) 16px;
  color: var(--main-dark-gray);
  height: unset;
  border: none;
  font-family: inherit;
  line-height: 16px;
}

.Rc6TnW_autocomplete .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input::placeholder {
  color: var(--color-gray-600);
  opacity: 1;
}

.Rc6TnW_autocomplete .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiOutlinedInput-notchedOutline {
  border: none;
}

.Rc6TnW_popper {
  border-radius: 4px;
  box-shadow: 0 3px 7px #24306733;
}

.Rc6TnW_popper .MuiPaper-root {
  box-shadow: none;
  color: var(--color-gray-900);
  border: none;
}

.Rc6TnW_popper .MuiPaper-root .MuiAutocomplete-listbox .MuiAutocomplete-option {
  background-color: var(--color-white);
  padding: 0;
}

.Rc6TnW_popper .MuiPaper-root .MuiAutocomplete-listbox .Mui-focused {
  background-color: var(--color-blue-50);
  border-radius: 2px;
}

.Rc6TnW_option {
  cursor: pointer;
  margin: 0 12px 0 16px;
}

.Rc6TnW_option .Rc6TnW_optionContent {
  margin: 0;
  padding: 6px;
}

.Rc6TnW_noOption.MuiAutocomplete-noOptions {
  padding: var(--indent-xs);
}

.Rc6TnW_loader {
  position: relative;
}

.Rc6TnW_label {
  margin-bottom: var(--indent-xxs);
  display: block;
}

.Rc6TnW_labelContent {
  justify-content: space-between;
  column-gap: var(--indent-xxs);
  color: var(--color-gray-800);
  display: flex;
}

.Rc6TnW_required:after {
  content: " *";
  color: var(--color-red-error);
}

.Rc6TnW_errorMessage {
  color: var(--color-red-error);
  margin-top: 2px;
}

.v7bY_W_header {
  height: 32px;
  white-space: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.v7bY_W_header h2 {
  color: var(--color-gray-900);
}

.v7bY_W_headerTitle {
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
}

.v7bY_W_customHeader {
  text-overflow: ellipsis;
  flex-grow: 2;
  overflow: hidden;
}

.v7bY_W_container {
  max-width: 530px;
  min-width: 530px;
  width: 100%;
  background: var(--color-white);
  border-radius: 8px;
  flex-direction: column;
  gap: 20px;
  padding: 32px;
  font-size: 14px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 6px 10px #767b9233;
}

.v7bY_W_content {
  min-height: 0;
  flex-direction: column;
  display: flex;
}

._2hWFEa_checkbox {
  display: flex;
}

._2hWFEa_checkbox._2hWFEa_noWrap {
  overflow: hidden;
}

._2hWFEa_checkbox .MuiCheckbox-root {
  margin: 0;
  padding: 0;
}

._2hWFEa_checkbox .MuiCheckbox-root.Mui-disabled > svg {
  fill: var(--color-gray-400-hover-element);
  stroke: var(--color-gray-400-hover-element);
}

._2hWFEa_checkbox .MuiCheckbox-root.Mui-disabled > svg > rect:first-child {
  fill: var(--color-gray-400-hover-element);
}

._2hWFEa_checkbox .MuiCheckbox-root.Mui-disabled > svg > rect:last-child {
  stroke: var(--color-gray-400-hover-element);
}

._2hWFEa_checkbox .MuiCheckbox-root.Mui-disabled.MuiCheckbox-indeterminate > svg, ._2hWFEa_checkbox .MuiCheckbox-root.Mui-disabled.Mui-checked > svg {
  fill: var(--color-gray-600);
  stroke: var(--color-gray-600);
}

._2hWFEa_checkbox .MuiCheckbox-root.Mui-disabled.MuiCheckbox-indeterminate > svg > rect:first-child, ._2hWFEa_checkbox .MuiCheckbox-root.Mui-disabled.Mui-checked > svg > rect:first-child {
  fill: var(--color-gray-600);
}

._2hWFEa_checkbox .MuiCheckbox-root.Mui-disabled.MuiCheckbox-indeterminate > svg > rect:last-child, ._2hWFEa_checkbox .MuiCheckbox-root.Mui-disabled.Mui-checked > svg > rect:last-child {
  stroke: var(--color-gray-600);
}

._2hWFEa_checkbox .MuiFormControlLabel-root {
  text-overflow: ellipsis;
  gap: 8px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

._2hWFEa_checkbox .MuiFormControlLabel-root input {
  margin: 0;
}

._2hWFEa_checkbox .MuiFormControlLabel-root.Mui-disabled > span {
  color: var(--color-gray-600);
}

.uNTWLG_searchInput {
  background-color: var(--color-white);
  border: 1px solid var(--color-border-chat);
  border-radius: 18px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  display: flex;
}

.uNTWLG_disabled {
  background: var(--color-gray-300);
  border-color: var(--color-border-chat);
}

.uNTWLG_isError {
  border-color: var(--color-red-error);
}

.uNTWLG_isFocused {
  border: 1px solid var(--color-blue-500);
  border-radius: 31px;
  box-shadow: 0 0 5px #2369f14f;
}

.uNTWLG_isHovered {
  border-color: var(--color-blue-200);
}

.uNTWLG_inputField {
  color: var(--color-gray-900);
  border: none;
  outline: none;
  flex-grow: 1;
}

.uNTWLG_inputField::placeholder {
  color: var(--color-gray-600);
}

.uNTWLG_inputField:disabled {
  background: inherit;
}

.uNTWLG_iconBtn {
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
  min-height: 18px;
  font: inherit;
  color: inherit;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 0;
}

.uNTWLG_iconBtn:hover {
  background-color: #0000;
}

.uNTWLG_iconBtn:hover svg path {
  stroke: var(--color-gray-900);
}

.uNTWLG_testInput {
  width: 100%;
  flex-direction: row;
  gap: 10px;
  display: flex;
}

.uNTWLG_input {
  border: 3px solid #000;
  outline: none;
  padding: 10px;
}

.sou-MW_root {
  height: 16px;
}

.sou-MW_helpIcon {
  width: 18px;
  height: 18px;
}

.sou-MW_helpIcon:hover {
  cursor: pointer;
}

.sou-MW_helpIcon:hover path {
  fill: var(--color-blue-500);
}

.sou-MW_infoContainer {
  width: 100%;
  z-index: 10;
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-blue-500);
  border-top: 4px solid var(--color-blue-500);
  border-radius: 4px;
  padding: 12px;
  position: absolute;
  top: 34px;
  left: 0;
  box-shadow: 0 1px 4px #767b924d;
}

.sEALia_expendableTextContent {
  margin: 0;
  line-height: 1.2em;
}

.sEALia_expandableContentButton {
  color: var(--color-blue-500);
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  margin-top: 12px;
  padding: 0;
}

.Lls_UW_collapsibleArea {
  flex-direction: column;
  display: flex;
}

.Lls_UW_visibleHeader {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.Lls_UW_visibleContent {
  flex-grow: 1;
}

.Lls_UW_button:hover {
  background-color: #0000;
}

.Lls_UW_collapsibleContent {
  justify-content: space-between;
  display: flex;
}

.Lls_UW_horizontalLine {
  color: var(--color-gray-300);
  opacity: .3;
  size: 1px;
  width: 100%;
}

.ayqcSa_headerTextColor {
  margin-left: var(--padding-xxs);
  color: var(--color-gray-900);
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: auto;
  overflow: hidden;
}

.ayqcSa_selectedHeader {
  background: var(--color-blue-50);
}

.ayqcSa_headerContainer {
  max-height: 34px;
  padding: var(--indent-xs) var(--indent-s);
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.ayqcSa_filterIcon {
  margin-right: 8px;
}

.ayqcSa_buttonIcon {
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
  min-height: 18px;
  font: inherit;
  color: inherit;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 0;
}

.ayqcSa_closeIcon {
  font: inherit;
  color: inherit;
  cursor: pointer;
  max-height: 18px;
  max-width: 18px;
  min-width: 18px;
  min-height: 18px;
  background-color: #0000;
  border: none;
  padding: 0;
}

.ayqcSa_filterOptionItem {
  align-items: center;
  gap: var(--padding-xs);
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-direction: row;
  display: flex;
  overflow: hidden;
}

.ayqcSa_filterOptionList {
  padding: var(--indent-xs) var(--indent-s);
  background: var(--color-white);
  border-radius: 4px;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  display: flex;
  box-shadow: 0 3px 7px #24306733;
}

.ayqcSa_labelContainer {
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 0;
  display: flex;
}

.ayqcSa_label {
  color: var(--color-black);
  cursor: pointer;
}

._5Kp6YG_container {
  box-sizing: border-box;
  width: 100%;
}

._5Kp6YG_container .MuiButtonBase-root {
  text-transform: none;
}

._5Kp6YG_container .MuiButtonBase-root.Mui-disabled > span {
  color: var(--color-gray-600);
}

._5Kp6YG_container ._5Kp6YG_tabs {
  margin-bottom: 20px;
  position: relative;
}

._5Kp6YG_container ._5Kp6YG_delimiter {
  height: 2px;
  width: 100%;
  background-color: var(--color-gray-500);
  position: absolute;
  bottom: 0;
}

._5Kp6YG_container .MuiTabs-indicator {
  height: 2px;
  background-color: var(--color-gray-900);
}

._7ulfpG_badge {
  background: var(--color-red-error);
  height: 6px;
  min-width: 6px;
  border-radius: 50px;
  position: absolute;
  top: 13px;
  right: 6px;
}

._7NRhTW_title {
  color: var(--color-gray-800);
}

.L0Fd8q_container .MuiAccordion-root {
  box-shadow: none;
  border-radius: 0;
}

.L0Fd8q_container .MuiAccordion-root:first-of-type, .L0Fd8q_container .MuiAccordion-root:last-of-type {
  border-radius: 0;
}

.L0Fd8q_container .MuiAccordionSummary-root {
  padding: var(--padding-xxs, 4px) var(--padding-xs, 8px);
  min-height: unset;
  box-shadow: none;
  column-gap: 10px;
}

.L0Fd8q_container .MuiAccordionSummary-root.Mui-expanded {
  min-height: unset;
  margin: 0;
}

.L0Fd8q_container .MuiAccordionSummary-content {
  margin: 0;
}

.L0Fd8q_container .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}

.L0Fd8q_container .MuiAccordionDetails-root {
  padding: 0;
}

.L0Fd8q_container .L0Fd8q_paddingDetails.MuiAccordionDetails-root {
  padding: var(--padding-xxs, 4px) var(--padding-xs, 8px) 0;
}

.L0Fd8q_titleNoWrap .MuiAccordionSummary-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.YZ9m6q_popper .MuiTooltip-tooltip {
  background: var(--color-gray-800);
  border: 1px solid var(--color-gray-800);
  max-width: none;
  border-radius: 2px;
  padding: 6px 8px;
}

.YZ9m6q_popper .MuiTooltip-arrow:before {
  background: var(--color-gray-800);
}

.YZ9m6q_popper.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-arrow, .YZ9m6q_popper.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-arrow {
  width: 100%;
  left: 0;
  right: 0;
}

.YZ9m6q_popper.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-arrow {
  transform: translate3d(calc(5px - 50%), 0, 0) !important;
}

.YZ9m6q_popper.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-arrow {
  transform: translate3d(calc(50% - 5px), 0, 0) !important;
}

.YZ9m6q_trigger {
  line-height: 0;
}

.oZkIZq_label {
  margin-bottom: var(--indent-xxs, 4px);
  display: block;
}

.oZkIZq_withLabel {
  justify-content: right;
}

.oZkIZq_labelContent {
  justify-content: space-between;
  column-gap: var(--indent-xxs, 4px);
  color: var(--color-gray-800, #5b5f73);
  display: flex;
}

.oZkIZq_labelTextContainer {
  gap: var(--indent-xxs);
  display: flex;
}

.oZkIZq_required:after {
  content: " *";
  color: var(--color-red-error);
}

.oZkIZq_input {
  box-sizing: border-box;
  width: 100%;
  padding: 0 var(--indent-m, 16px);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  height: 34px;
  color: var(--color-gray-900);
  border-radius: 4px;
  outline: none;
}

.oZkIZq_input.oZkIZq_isError {
  border-color: var(--color-red-error);
}

.oZkIZq_input::placeholder {
  color: var(--color-gray-600);
}

.oZkIZq_input:disabled {
  border-color: var(--color-blue-200);
  background: inherit;
}

.oZkIZq_input:hover {
  border-color: var(--color-blue-200);
}

.oZkIZq_input:focus {
  border: 1px solid var(--color-blue-500);
  box-shadow: 0 0 5px #2369f14f;
}

.oZkIZq_errorMessage {
  color: var(--color-red-error);
  margin-top: 2px;
}

._7zZGiW_dynamicList {
  width: 100%;
  height: 100%;
}

._7zZGiW_list._7zZGiW_loading {
  display: none;
}

._7qp8gG_DatePicker .MuiFormControl-root .MuiInputBase-root {
  border: 1px solid var(--color-gray-500);
  border-radius: var(--indent-xxs);
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: var(--color-gray-900);
  padding: 0;
}

._7qp8gG_DatePicker .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: var(--indent-xs) var(--indent-s);
  border-right: 1px solid var(--color-gray-500);
  height: 16px;
}

._7qp8gG_DatePicker .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input::placeholder {
  color: var(--color-gray-600);
  opacity: 1;
}

._7qp8gG_DatePicker .MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-error {
  border: 1px solid var(--color-red-error);
  border-radius: var(--indent-xxs);
}

._7qp8gG_DatePicker .MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-error::placeholder {
  color: var(--color-gray-600);
  opacity: 1;
}

._7qp8gG_DatePicker .MuiFormControl-root .MuiInputBase-root:hover {
  border: 1px solid var(--color-blue-200);
}

._7qp8gG_DatePicker .MuiFormControl-root ._7qp8gG_calendarIcon:hover > path {
  fill: var(--color-blue-500);
}

._7qp8gG_DatePicker .MuiFormControl-root .MuiInputBase-root.Mui-disabled {
  background-color: var(--color-gray-300);
  color: var(--color-gray-700);
}

._7qp8gG_DatePicker .MuiFormControl-root .MuiInputBase-root.Mui-disabled ._7qp8gG_calendarIcon > path {
  fill: var(--color-gray-400);
}

._7qp8gG_DatePicker .MuiFormControl-root .MuiInputBase-root.Mui-disabled:hover {
  border: 1px solid var(--color-gray-500);
}

._7qp8gG_DatePicker .MuiInputAdornment-root {
  height: 34px;
  width: 48px;
  margin: 0;
}

._7qp8gG_DatePicker .MuiInputAdornment-root .MuiButtonBase-root {
  padding: 0;
  left: 8px;
}

._7qp8gG_DatePicker .MuiOutlinedInput-notchedOutline {
  border: none;
  padding: 0;
}

._7qp8gG_DatePicker .MuiIconButton-root:hover {
  background: none;
}

._7qp8gG_DatePicker .MuiFormHelperText-root {
  letter-spacing: 0;
  color: var(--color-red-error);
  margin: 0;
  font-family: inherit;
  font-size: 10px;
}

._7qp8gG_active .MuiInputBase-root {
  box-shadow: 0 0 5px #2369f14f;
  border: 1px solid var(--color-blue-400) !important;
}

._7qp8gG_active .MuiInputBase-root .MuiInputBase-input {
  padding: var(--indent-xs) var(--indent-s);
  border: none;
  border-right: 1px solid var(--color-gray-500);
}

._7qp8gG_arrowLeft {
  position: absolute;
  top: -8px;
  left: 6px;
}

._7qp8gG_arrowRight {
  position: absolute;
  top: -8px;
  left: 224px;
}

._7qp8gG_paper {
  width: 276px;
  margin-top: 4px;
  box-shadow: 0 6px 22px #20222d36;
}

._7qp8gG_paper .MuiCalendarPicker-root {
  width: 276px;
  height: 312px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

._7qp8gG_paper .css-epd502 {
  width: 276px;
  height: 312px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

._7qp8gG_paper .MuiPickersCalendarHeader-label {
  font-weight: var(--text-bold);
  color: var(--color-gray-800);
  flex-grow: 1;
}

._7qp8gG_paper .MuiPickersCalendarHeader-label:first-letter {
  text-transform: uppercase;
}

._7qp8gG_paper .MuiDayPicker-header {
  box-sizing: border-box;
  width: 264px;
  background-color: var(--color-gray-200);
  justify-content: space-between;
  margin: 0 6px 6px;
  padding: 4px 8px;
}

._7qp8gG_paper .MuiDayPicker-header .MuiDayPicker-weekDayLabel {
  width: 28px;
  height: 16px;
  font-family: var(--font);
  color: var(--color-gray-800);
}

._7qp8gG_paper .MuiDayPicker-monthContainer {
  box-sizing: border-box;
  width: 264px;
  margin: 0 6px;
  padding: 4px 8px;
}

._7qp8gG_paper .MuiPickersCalendarHeader-root {
  box-sizing: border-box;
  width: 264px;
  justify-content: space-between;
  margin: 16px 6px;
  padding: 6px;
  font-family: inherit;
}

._7qp8gG_paper .MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-labelContainer {
  text-align: center;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  flex-grow: 1;
  margin: 0;
}

._7qp8gG_paper .MuiCalendarOrClockPicker-root {
  height: 312px;
}

._7qp8gG_paper .MuiDayPicker-weekContainer {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  justify-content: space-between;
  margin-bottom: 6px;
}

._7qp8gG_paper .MuiPickersDay-root {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  width: 28px;
  height: 28px;
  color: var(--color-gray-900);
}

._7qp8gG_paper .MuiPickersDay-root:hover {
  background-color: var(--color-blue-100);
  border-color: var(--color-blue-100);
  font-weight: var(--text-regular);
  color: var(--color-gray-900);
}

._7qp8gG_paper .MuiPickersDay-root.Mui-selected {
  background-color: var(--color-white);
  color: var(--color-gray-900);
  border: 1px solid var(--color-blue-500);
  font-weight: var(--text-regular);
}

._7qp8gG_paper .MuiPickersDay-root.Mui-selected:hover {
  background-color: var(--color-blue-100);
  border-color: var(--color-blue-100);
  color: var(--color-gray-900);
}

._7qp8gG_paper .MuiPickersDay-root.Mui-disabled {
  color: var(--color-gray-500);
}

._7qp8gG_paper .MuiPickersDay-root.MuiPickersDay-today {
  background-color: var(--color-blue-500);
  border-color: var(--color-blue-500);
  color: var(--color-white);
  font-weight: var(--text-semibold);
}

._7qp8gG_paper .MuiPickersDay-root.MuiPickersDay-today:hover {
  background-color: var(--color-blue-100);
  border-color: var(--color-blue-100);
  color: var(--color-gray-900);
}

._7qp8gG_paper .MuiDayPicker-slideTransition {
  height: 220px;
  min-height: 220px;
}

._7qp8gG_paper .MuiPickersArrowSwitcher-root {
  position: absolute;
}

._7qp8gG_paper .MuiPickersCalendarHeader-switchViewIcon {
  display: none;
}

._7qp8gG_paper .MuiYearPicker-root {
  height: 240px;
  font-size: inherit;
  font-family: var(--font);
  font-weight: inherit;
}

._7qp8gG_paper .MuiYearPicker-root .PrivatePickersYear-root {
  flex-basis: 20%;
}

._7qp8gG_paper .MuiYearPicker-root .PrivatePickersYear-yearButton {
  width: 45px;
  height: 24px;
  font-size: var(--small-text-size);
  font-family: var(--font);
  font-weight: inherit;
  color: var(--color-gray-800);
  background-color: var(--color-gray-200);
  border-radius: 4px;
  margin: 2px;
  padding: 0;
}

._7qp8gG_paper .MuiYearPicker-root .PrivatePickersYear-yearButton:hover {
  background-color: var(--color-gray-400);
}

._7qp8gG_paper .MuiYearPicker-root .PrivatePickersYear-yearButton.Mui-selected {
  background-color: var(--color-blue-500);
  color: var(--color-white);
  border: 1px solid var(--color-blue-500);
}

._7qp8gG_paper .MuiYearPicker-root .PrivatePickersYear-yearButton.Mui-disabled {
  color: var(--color-gray-500);
}

._7qp8gG_paper .MuiYearPicker-root .PrivatePickersYear-yearButton.Mui-disabled:hover {
  background-color: var(--color-gray-200);
}

.EQCmra_link {
  color: var(--color-blue-500);
  text-decoration: none;
}

.EQCmra_link:visited {
  color: var(--color-blue-300);
}

.EQCmra_link:hover {
  color: var(--color-blue-700);
}

.jMbCFq_container {
  flex-direction: column;
  display: flex;
}

.jMbCFq_container .jMbCFq_label {
  margin-bottom: var(--indent-xxs);
  align-items: center;
  gap: var(--indent-xxs);
  display: flex;
}

.jMbCFq_container .jMbCFq_label .jMbCFq_labelContent {
  justify-content: space-between;
  column-gap: var(--indent-xxs);
  color: var(--color-gray-800);
  display: flex;
}

.jMbCFq_container .jMbCFq_label .jMbCFq_labelContent .jMbCFq_required:after {
  content: " *";
  color: var(--color-red-error);
}

.jMbCFq_endAdornment {
  margin-left: auto;
}

.TG-A7W_root {
  width: 100%;
}

.TG-A7W_root .MuiInputBase-root {
  color: inherit;
  font: inherit;
}

.TG-A7W_root .MuiInputBase-root input {
  color: inherit;
  font: inherit;
  padding: var(--indent-xs) 16px;
  height: auto;
  line-height: 18px;
}

.TG-A7W_root .MuiInputBase-root input::placeholder {
  color: var(--color-gray-600);
  opacity: 1;
}

.TG-A7W_root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-gray-500);
}

.TG-A7W_root .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-blue-200);
}

.TG-A7W_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused > fieldset {
  border: 1px solid var(--color-blue-500);
  border-radius: 4px;
  box-shadow: 0 0 5px #2369f14f;
}

.TG-A7W_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  background-color: var(--color-gray-300);
}

.TG-A7W_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > fieldset {
  color: var(--color-gray-700);
  border: 1px solid #0000;
  border-radius: 4px;
}

.TG-A7W_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > input {
  -webkit-text-fill-color: var(--color-gray-700);
}

.TG-A7W_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > input::placeholder {
  color: var(--color-gray-600);
}

.TG-A7W_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-error > fieldset {
  border: 1px solid var(--color-red-error);
  border-radius: 4px;
}

.TG-A7W_errorMessage {
  color: var(--color-red-error);
  margin-top: 2px;
}

.TG-A7W_background {
  background-color: var(--color-white);
}

.wk97_q_container {
  width: 100%;
  position: relative;
}

.wk97_q_input {
  width: 100%;
  resize: none;
  padding: var(--indent-xxs) var(--indent-xs);
  font-family: var(--font);
  font-size: var(--main-text-size);
  font-weight: var(--text-regular);
  line-height: var(--l1-line-height);
  color: var(--main-dark-gray);
  border: none;
  border-radius: 4px;
  outline: none;
}

.wk97_q_input::placeholder {
  color: var(--color-gray-600);
}

.wk97_q_input:hover {
  background-color: var(--hover-color);
}

.wk97_q_inputContainer {
  border: 1px solid #0000;
  align-items: center;
  display: flex;
}

.wk97_q_controls {
  column-gap: var(--indent-xxs);
  padding: var(--indent-xxs);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: 2px;
  display: flex;
  box-shadow: 0 0 16px #423d6321;
}

.wk97_q_popper {
  display: flex;
}

.wk97_q_popper.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip, .wk97_q_popper.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip {
  padding: 0;
}

.wk97_q_popper.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip {
  margin: var(--indent-xxs) 0 0;
}

.wk97_q_popper.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip {
  margin: 0 0 var(--indent-xxs) 0;
}

.wk97_q_focused ~ :not(.wk97_q_error) .wk97_q_inputContainer {
  background-color: var(--color-white);
  border: 1px solid var(--color-blue-400);
  border-radius: 4px;
}

.wk97_q_focused .wk97_q_controls {
  opacity: 1;
  visibility: visible;
}

.wk97_q_error, .wk97_q_focused ~ .wk97_q_error .wk97_q_inputContainer {
  border: 1px solid var(--color-red-error);
  border-radius: var(--indent-xxs);
}

.xEoPHG_switch {
  cursor: pointer;
  gap: 8px;
  display: flex;
}

.xEoPHG_switch.xEoPHG_disabled {
  cursor: default;
}

.xEoPHG_switch .MuiSwitch-root {
  width: 32px;
  height: 16px;
  border-radius: 22px;
  padding: 0;
}

.xEoPHG_switch .MuiSwitch-root .MuiSwitch-track {
  background-color: var(--color-gray-700);
  opacity: 1;
  border-radius: 13px;
}

.xEoPHG_switch .MuiSwitch-root .MuiSwitch-switchBase {
  margin: 2px 3px;
  padding: 0;
  transition-duration: .3s;
}

.xEoPHG_switch .MuiSwitch-root .MuiSwitch-switchBase .MuiSwitch-thumb {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  color: var(--color-white);
  box-shadow: 0 1px 2px #2827322e;
}

.xEoPHG_switch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
  color: var(--color-white);
  transform: translateX(14px);
}

.xEoPHG_switch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: var(--color-blue-500);
  opacity: 1;
  border: 0;
}

.xEoPHG_switch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked.Mui-disabled + .MuiSwitch-track {
  opacity: 1;
  background-color: var(--color-blue-200);
}

.xEoPHG_switch .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked.Mui-disabled .MuiSwitch-thumb {
  color: var(--color-gray-100);
  box-shadow: 0 1px 2px #2827322e;
}

.xEoPHG_switch .MuiSwitch-root .MuiSwitch-switchBase.Mui-focusVisible .MuiSwitch-thumb {
  color: var(--color-blue-500);
  border: 6px solid var(--color-white);
  border-radius: 22px;
  box-shadow: 0 1px 2px #2827322e;
}

.xEoPHG_switch .MuiSwitch-root .Mui-disabled + .MuiSwitch-track {
  background-color: var(--color-gray-400);
  opacity: 1;
}

.xEoPHG_label.xEoPHG_disabled {
  color: var(--color-gray-800);
  cursor: default;
}

.hRqx-G_numericInput {
  color: var(--color-gray-900);
  background-color: var(--color-white);
}

.hRqx-G_numericInput .MuiTextField-root {
  width: 100%;
}

.hRqx-G_numericInput .MuiTextField-root .MuiInputBase-root {
  color: inherit;
  font: inherit;
  padding: 0;
}

.hRqx-G_numericInput .MuiTextField-root .MuiInputBase-root input {
  color: inherit;
  font: inherit;
  padding: var(--indent-xs) var(--indent-m);
  height: unset;
  line-height: 18px;
}

.hRqx-G_numericInput .MuiTextField-root .MuiInputBase-root input::placeholder {
  color: var(--color-gray-600);
  opacity: 1;
}

.hRqx-G_numericInput .MuiTextField-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-gray-500);
}

.hRqx-G_numericInput .MuiTextField-root .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-blue-200);
}

.hRqx-G_numericInput .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused > fieldset {
  border: 1px solid var(--color-blue-500);
  border-radius: 4px;
  box-shadow: 0 0 5px #2369f14f;
}

.hRqx-G_numericInput .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  background-color: var(--color-gray-300);
}

.hRqx-G_numericInput .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > fieldset {
  color: var(--color-gray-700);
  border: 1px solid #0000;
  border-radius: 4px;
}

.hRqx-G_numericInput .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > input {
  -webkit-text-fill-color: var(--color-gray-700);
}

.hRqx-G_numericInput .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > input::placeholder {
  color: var(--color-gray-600);
}

.hRqx-G_numericInput .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-error > fieldset {
  border: 1px solid var(--color-red-error);
  border-radius: 4px;
}

.hRqx-G_adornment {
  justify-content: flex-end;
  display: flex;
}

.hRqx-G_adornment .hRqx-G_units {
  color: var(--color-gray-600);
  padding: 0 16px 0 0;
}

.hRqx-G_spinControl {
  border: 1px solid #0000;
  border-left-color: var(--color-gray-500);
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.hRqx-G_spinControl .hRqx-G_delimiter {
  height: 0;
  width: 100%;
  border-bottom: 1px solid var(--color-gray-500);
}

.hRqx-G_spinControlDisabled {
  border: 1px solid var(--color-gray-600);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.hRqx-G_spinControlDisabled .hRqx-G_delimiter {
  border-bottom: 1px solid var(--color-gray-600);
}

.hRqx-G_disabled {
  background-color: var(--color-gray-200);
  border-radius: 4px;
}

.hRqx-G_spinButtonDisabled {
  cursor: default;
}

.hRqx-G_spinButtonDisabled svg > path, .hRqx-G_spinButtonDisabled:hover svg > path {
  stroke: var(--color-gray-600);
}

.hRqx-G_spinButton {
  height: 16px;
  align-items: center;
  padding: 0 6px;
  display: flex;
}

.hRqx-G_spinButtonHovered:hover svg > path {
  stroke: var(--color-blue-500);
}

.iuAIna_hint {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.iuAIna_container {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  transform: translate(-6px, 6px);
}

.iuAIna_container .iuAIna_content {
  border: 1px solid var(--color-purple-heart);
  border-top: 3px solid var(--color-purple-heart);
  background-color: var(--color-white);
  border-radius: 3px;
  padding: 20px 16px 8px;
  position: relative;
  box-shadow: 0 0 11px #1c50de29;
}

.iuAIna_container .iuAIna_content .iuAIna_info {
  width: 32px;
  height: 32px;
  background-color: var(--color-purple-heart);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: -12px;
  left: -12px;
  box-shadow: 0 3px 5px #7c40b8ab;
}

.iuAIna_container .iuAIna_content .iuAIna_text {
  color: var(--color-gray-900);
}

.iuAIna_pointer {
  flex-direction: column;
  align-items: center;
  display: flex;
  transform: translate(-6px, 6px);
}

.iuAIna_pointer .iuAIna_line {
  width: 0;
  height: 8px;
  border: 1px solid var(--color-purple-heart);
}

.iuAIna_pointer .iuAIna_circle {
  width: 12px;
  height: 12px;
  background-color: var(--color-purple-heart);
  border-radius: 50%;
}

.iuAIna_highlight {
  border: 2px solid var(--color-purple-heart);
  border-radius: 2px;
}

.iuAIna_children {
  width: 100%;
}

.BzVgtW_menu.MuiPaper-root {
  margin-top: var(--indent-xxs);
  border-radius: var(--indent-xxs);
  background-color: var(--color-white);
  box-shadow: 0 3px 7px #24306733;
  z-index: 0 !important;
}

.BzVgtW_subMenu {
  margin: 0 var(--indent-xs) 0 -8px;
  border-radius: var(--indent-xxs);
  background-color: var(--color-white);
  box-shadow: 0 3px 7px #24306733 !important;
}

.BzVgtW_list {
  gap: var(--indent-xxs);
  flex-direction: column;
  display: flex;
  padding: var(--indent-xs) !important;
}

.BzVgtW_list li[aria-disabled="true"] {
  pointer-events: inherit !important;
}

.BzVgtW_list li[aria-disabled="true"]:hover, .BzVgtW_list li[aria-disabled="true"]:focus {
  background: #fff !important;
}

.BzVgtW_list li[aria-disabled="true"]:active {
  pointer-events: none !important;
  background: #fff !important;
}

.BzVgtW_item.MuiMenuItem-root {
  padding: var(--indent-xs);
  border-radius: var(--indent-xxs);
  width: 100%;
}

.BzVgtW_item:hover.MuiMenuItem-root, .BzVgtW_active {
  background-color: var(--color-blue-50);
}

.BzVgtW_delimiter {
  width: 100%;
  border-top: 1px solid var(--color-gray-400);
}

.BzVgtW_container {
  flex-direction: column;
  display: flex;
}

.BzVgtW_groupItem {
  cursor: pointer;
  gap: var(--indent-xxs);
  flex-direction: column;
  display: flex;
}

.BzVgtW_groupItem:hover {
  background-color: var(--color-blue-50);
  border-radius: var(--indent-xxs);
}

.BzVgtW_disabled .MuiMenuItem-disabled {
  pointer-events: none;
  cursor: default;
  opacity: .5;
}

.BzVgtW_subMenuItem {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.BzVgtW_subMenuRoot {
  margin: 0 var(--indent-xs) !important;
  z-index: 1500 !important;
}

.BzVgtW_active {
  background-color: var(--color-blue-50) !important;
}

.BzVgtW_drawerContainer {
  background: var(--color-white);
  height: auto;
  overflow: hidden;
}

.BzVgtW_drawerContent {
  padding-bottom: var(--indent-m);
  flex-direction: column;
  display: flex;
  position: relative;
}

.BzVgtW_drawerContent .BzVgtW_drawerHeader {
  justify-content: space-between;
  display: flex;
}

.BzVgtW_drawer {
  border-top-left-radius: var(--indent-xs);
  border-top-right-radius: var(--indent-xs);
  padding: var(--indent-s) var(--indent-m);
  box-shadow: 0 8px 24px #1e222e1f, 0 4px 8px #1e222e0a;
}

.BzVgtW_swipeableArea {
  height: 8px;
  padding: var(--indent-ml) 0;
  justify-content: center;
  display: flex;
}

.BzVgtW_puller {
  width: 60px;
  height: 7px;
  background-color: #d9d9d9;
  border-radius: 15px;
}

.IEh_6G_select {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  width: 100%;
  border-radius: 4px;
}

.IEh_6G_select:hover {
  border-color: var(--color-blue-200);
}

.IEh_6G_select.IEh_6G_error {
  border: 1px solid var(--color-red-error);
  border-radius: 4px;
}

.IEh_6G_select.IEh_6G_active {
  border: 1px solid var(--color-blue-400);
  border-radius: 4px;
  box-shadow: 0 0 5px #2369f14f;
}

.IEh_6G_select.IEh_6G_disabled, .IEh_6G_select.IEh_6G_disabled:hover {
  border: 1px solid var(--color-gray-300);
}

.IEh_6G_select .MuiAutocomplete-root {
  text-transform: none;
  padding: 0;
}

.IEh_6G_select .MuiAutocomplete-root .MuiInputBase-root {
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  padding: 0 !important;
}

.IEh_6G_select .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-notchedOutline {
  padding: 0;
}

.IEh_6G_select .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root {
  border: none;
  padding: 0;
}

.IEh_6G_select .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  background-color: var(--color-gray-300);
}

.IEh_6G_select .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > input {
  -webkit-text-fill-color: var(--color-gray-700);
}

.IEh_6G_select .MuiAutocomplete-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > input::placeholder {
  color: var(--color-gray-600);
}

.IEh_6G_select .MuiAutocomplete-root .MuiInputBase-root .MuiAutocomplete-endAdornment {
  border-left: 1px solid var(--color-gray-500);
  padding: 7px 10px 7px 8px;
  display: flex;
  position: static;
}

.IEh_6G_select .MuiAutocomplete-root .MuiInputBase-root .MuiIconButton-root {
  padding: 0;
}

.IEh_6G_select .MuiAutocomplete-root .MuiInputBase-root.Mui-disabled .MuiAutocomplete-endAdornment {
  border-left-color: var(--color-gray-300);
}

.IEh_6G_select .MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  text-transform: none;
  padding: 0;
}

.IEh_6G_select .MuiAutocomplete-root .MuiAutocomplete-inputRoot:hover {
  border-color: var(--color-blue-200);
}

.IEh_6G_select .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding: var(--indent-xs) 16px;
  color: var(--main-dark-gray);
  height: unset;
  font-family: inherit;
  line-height: 16px;
}

.IEh_6G_select .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input::placeholder {
  color: var(--color-gray-600);
  opacity: 1;
}

.IEh_6G_select .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiOutlinedInput-notchedOutline {
  border: none;
}

.IEh_6G_arrow {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.IEh_6G_arrow:hover {
  background-color: #0000;
}

.IEh_6G_popper {
  border-radius: 4px;
  box-shadow: 0 3px 7px #24306733;
}

.IEh_6G_popper .MuiPaper-root {
  box-shadow: none;
  color: var(--color-gray-900);
  border: none;
}

.IEh_6G_popper .MuiPaper-root .MuiAutocomplete-listbox .MuiAutocomplete-option {
  background-color: var(--color-white);
  padding: 0;
}

.IEh_6G_popper .MuiPaper-root .MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 8px;
  background-color: var(--color-gray-200);
}

.IEh_6G_popper .MuiPaper-root .MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  background: #0c236233;
  border-radius: 13px;
}

.IEh_6G_popper .MuiPaper-root .MuiAutocomplete-listbox li[aria-disabled="true"].IEh_6G_disablePointerEvents {
  pointer-events: inherit;
}

.IEh_6G_popper .MuiPaper-root .MuiAutocomplete-listbox li[aria-disabled="true"].IEh_6G_disablePointerEvents:hover, .IEh_6G_popper .MuiPaper-root .MuiAutocomplete-listbox li[aria-disabled="true"].IEh_6G_disablePointerEvents:focus {
  background: var(--color-white);
}

.IEh_6G_popper .MuiPaper-root .MuiAutocomplete-listbox li[aria-disabled="true"].IEh_6G_disablePointerEvents:active {
  background: var(--color-white);
  pointer-events: none;
}

.IEh_6G_popper .MuiPaper-root .MuiAutocomplete-noOptions {
  font-size: inherit;
  font-weight: inherit;
  margin: 0 12px;
  padding: 0;
  font-family: inherit;
}

.IEh_6G_option {
  cursor: pointer;
  margin: 0 12px;
}

.IEh_6G_option.IEh_6G_disabled {
  cursor: default;
}

.IEh_6G_option:not(.IEh_6G_disabled):hover {
  background-color: var(--color-blue-50);
  border-radius: 2px;
}

.IEh_6G_option .IEh_6G_optionContent {
  margin: 0;
  padding: 6px;
}

.IEh_6G_noOption {
  color: var(--color-gray-700);
  padding: 8px 0;
}

.IEh_6G_startAdornment {
  padding-left: var(--indent-m);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.IEh_6G_errorMessage {
  color: var(--color-red-error);
  margin-top: 2px;
}

:root {
  --toastify-font-family: var(--font) !important;
  --toastify-color-light: var(--bg-toast-color) !important;
  --toastify-color-dark: var(--bg-toast-color) !important;
  --toastify-color-info: var(--bg-toast-color) !important;
  --toastify-color-success: var(--bg-toast-color) !important;
  --toastify-color-warning: var(--bg-toast-color) !important;
  --toastify-color-error: var(--bg-toast-color) !important;
  --toastify-toast-width: max-content !important;
  --toastify-text-color-dark: var(--color-white) !important;
  --toastify-toast-min-height: 50px !important;
}

.X9cc6a_CloseButton {
  margin: auto 0;
}

.X9cc6a_CloseButton > svg > path {
  stroke: var(--color-gray-100);
}

.X9cc6a_Text {
  display: inline;
}

.X9cc6a_toast {
  cursor: pointer;
}

.X9cc6a_toast.X9cc6a_notInteractive {
  cursor: default;
}

.X9cc6a_container {
  width: 100% !important;
  padding: 48px var(--indent-m) 0 var(--indent-m) !important;
}

.mjoDdG_timePicker {
  color: var(--color-gray-900);
  background-color: var(--color-white);
}

.mjoDdG_timePicker .MuiTextField-root {
  width: 100%;
}

.mjoDdG_timePicker .MuiTextField-root .MuiInputBase-root {
  color: inherit;
  font: inherit;
  padding: 0;
}

.mjoDdG_timePicker .MuiTextField-root .MuiInputBase-root input {
  color: inherit;
  font: inherit;
  padding: var(--indent-xs) var(--indent-m);
  border-right: 1px solid var(--color-gray-600);
  height: 18px;
}

.mjoDdG_timePicker .MuiTextField-root .MuiInputBase-root input.mjoDdG_placeholder {
  color: var(--color-gray-600);
}

.mjoDdG_timePicker .MuiTextField-root .MuiInputBase-root input::placeholder {
  color: var(--color-gray-600);
  opacity: 1;
}

.mjoDdG_timePicker .MuiTextField-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-gray-500);
}

.mjoDdG_timePicker .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  background-color: var(--color-gray-300);
}

.mjoDdG_timePicker .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > fieldset {
  border-radius: var(--indent-xxs);
  color: var(--color-gray-700);
  border: 1px solid #0000;
}

.mjoDdG_timePicker .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > input {
  -webkit-text-fill-color: var(--color-gray-700);
  border-right: none;
}

.mjoDdG_timePicker .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > input::placeholder {
  color: var(--color-gray-600);
}

.mjoDdG_timePicker .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-error > fieldset {
  border: 1px solid var(--color-red-error);
  border-radius: var(--indent-xxs);
}

.mjoDdG_timePicker .MuiTextField-root .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-blue-200);
}

.mjoDdG_timePicker .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused > fieldset {
  border: 1px solid var(--color-blue-400);
  border-radius: var(--indent-xxs);
  box-shadow: 0 0 5px #2369f14f;
}

.mjoDdG_alarm {
  cursor: pointer;
  margin-right: var(--indent-xs);
  border: 1px solid #0000;
}

.mjoDdG_popper {
  background: var(--color-white);
  margin-top: var(--indent-xxs);
  padding: var(--indent-xs) 0;
  border-radius: var(--indent-xxs);
  box-shadow: 0 3px 7px #24306733;
}

.mjoDdG_popper .mjoDdG_list {
  width: 100%;
  max-height: 254px;
  overflow-y: auto;
}

.mjoDdG_popper .mjoDdG_list .mjoDdG_timeStamp {
  margin: 0 var(--indent-xs);
  padding: var(--indent-xs);
  cursor: pointer;
}

.mjoDdG_popper .mjoDdG_list .mjoDdG_timeStamp:hover {
  background-color: var(--color-blue-50);
}

.mjoDdG_popper .mjoDdG_list::-webkit-scrollbar {
  width: 8px;
  background-color: var(--color-gray-200);
}

.mjoDdG_popper .mjoDdG_list::-webkit-scrollbar-thumb {
  background: #0c236233;
  border-radius: 13px;
}

.mjoDdG_popperIndex {
  z-index: 1301;
}

.fBjJqq_root {
  width: 100%;
}

.fBjJqq_root .MuiInputBase-root {
  color: inherit;
  font: inherit;
}

.fBjJqq_root .MuiInputBase-root input {
  color: inherit;
  font: inherit;
  padding: var(--indent-xs) 16px;
  height: auto;
  line-height: 18px;
}

.fBjJqq_root .MuiInputBase-root input::placeholder {
  color: var(--color-gray-600);
  opacity: 1;
}

.fBjJqq_root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-gray-500);
}

.fBjJqq_root .MuiInputBase-root.MuiInputBase-adornedStart input {
  padding: var(--indent-xs) 16px var(--indent-xs) 2px;
}

.fBjJqq_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  background-color: var(--color-gray-300);
}

.fBjJqq_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > fieldset {
  color: var(--color-gray-700);
  border: 1px solid #0000;
  border-radius: 4px;
}

.fBjJqq_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > input {
  -webkit-text-fill-color: var(--color-gray-700);
}

.fBjJqq_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > input::placeholder {
  color: var(--color-gray-600);
}

.fBjJqq_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-error > fieldset {
  border: 1px solid var(--color-red-error);
  border-radius: 4px;
}

.fBjJqq_root .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-blue-200);
}

.fBjJqq_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused > fieldset {
  border: 1px solid var(--color-blue-500);
  border-radius: 4px;
  box-shadow: 0 0 5px #2369f14f;
}

.fBjJqq_required:after {
  content: " *";
  color: var(--color-red-error);
}

.fBjJqq_errorMessage {
  color: var(--color-red-error);
  margin-top: 2px;
}

.fBjJqq_background {
  background-color: var(--color-white);
}

.TLQZFG_tableRoot.MuiTable-root {
  width: auto;
}

.TLQZFG_table .TLQZFG_th, .TLQZFG_table .TLQZFG_td {
  background: var(--color-white);
  overflow: hidden;
}

.TLQZFG_table.TLQZFG_sticky {
  overflow: scroll;
}

.TLQZFG_table.TLQZFG_sticky .TLQZFG_header, .TLQZFG_table.TLQZFG_sticky .TLQZFG_footer {
  z-index: 1;
  width: fit-content;
  position: sticky;
}

.TLQZFG_table.TLQZFG_sticky .TLQZFG_header {
  top: 0;
}

.TLQZFG_table.TLQZFG_sticky .TLQZFG_footer {
  bottom: 0;
}

.TLQZFG_table.TLQZFG_sticky .TLQZFG_body {
  z-index: 0;
  position: relative;
}

.TLQZFG_table.TLQZFG_sticky [data-sticky-td] {
  position: sticky;
}

.dr29Qa_cell {
  height: 1px;
  position: relative;
}

.dr29Qa_cell:not(.dr29Qa_unstyled) {
  padding: var(--padding-xxs) var(--padding-xs);
  color: var(--editable-table-cell-text-color);
  background-color: var(--editable-table-cell-background-color);
  border: 1px solid #f2f4f8;
  border-radius: 2px;
}

.XxrCDW_cell {
  padding: var(--padding-xxs) var(--padding-xs);
  color: var(--editable-table-cell-text-color);
  background-color: var(--editable-table-cell-background-color);
  height: 100%;
  border: 1px solid #f2f4f8;
  border-radius: 2px;
}

.cXS0Eq_container.cXS0Eq_cell {
  background-color: var(--editable-table-header-background-color);
  padding: var(--padding-xs);
}

.cXS0Eq_content {
  align-items: center;
  display: flex;
}

.GfQ2wG_container {
  visibility: hidden;
  flex-direction: column;
  gap: 2px;
  margin-left: auto;
  display: flex;
}

.GfQ2wG_visible {
  visibility: visible;
}

.GfQ2wG_icon {
  cursor: pointer;
  line-height: 0;
}

.GfQ2wG_icon.GfQ2wG_active path {
  fill: var(--editable-table-active-sort-background-color);
}

._1eh_Nq_container:hover ._1eh_Nq_sortController {
  visibility: visible;
}

.Dx6gHG_topPanel {
  width: 100%;
  background-color: var(--editable-table-header-background-color);
  padding: var(--padding-xs);
}

.Dx6gHG_topPanel:empty, .Dx6gHG_topPanel > div:empty {
  display: none;
}

.f-KtLq_cellWithEndAdornment {
  justify-content: flex-end;
  align-items: center;
  gap: var(--indent-xs);
  display: flex;
}

.f-KtLq_cellWithStartAdornment {
  justify-content: space-between;
  align-items: center;
  gap: var(--indent-xs);
  display: flex;
}

.F2cExW_container {
  height: 100%;
  width: 100%;
  background-color: var(--editable-table-delete-row-background-color);
  cursor: pointer;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.mE6DIq_row {
  position: relative;
}

.mE6DIq_deleteRow {
  width: 16px;
  height: 100%;
  opacity: 0;
  margin-right: -18px;
  position: absolute;
}

.mE6DIq_row:hover .mE6DIq_deleteRow {
  opacity: 1;
}

.mE6DIq_selected td {
  border-bottom: 1px solid var(--color-blue-400) !important;
  border-top: 1px solid var(--color-blue-400) !important;
}

.mE6DIq_selected td:last-of-type {
  border-right: 1px solid var(--color-blue-400) !important;
}

.mE6DIq_selected td:first-of-type {
  border-left: 1px solid var(--color-blue-400) !important;
}

.kP273q_cell {
  padding: var(--padding-xxs) var(--padding-xs);
  color: var(--main-dark-gray);
  background-color: var(--editable-table-cell-background-color);
  border: 1px solid #f2f4f8;
  border-radius: 2px;
}

.LYAUIa_table {
  border-spacing: 2px;
  border-collapse: separate;
  width: 100%;
}

:root {
  --editable-table-header-background-color: var(--color-alpha-blue-50);
  --editable-table-cell-background-color: var(--color-white);
  --editable-table-cell-text-color: var(--main-dark-gray);
  --editable-table-delete-row-background-color: #f2f4f8;
  --editable-table-active-sort-background-color: #767b92;
}

._10eRqa_chip {
  max-width: 100%;
}

._10eRqa_chip ._10eRqa_root {
  background-color: var(--color-alpha-blue-100);
  padding: var(--indent-xxs) var(--indent-xs);
  border-radius: var(--indent-xxs);
  align-items: center;
  gap: var(--indent-xs);
  height: fit-content;
  display: flex;
}

._10eRqa_chip ._10eRqa_root ._10eRqa_label {
  padding: 0;
}

._10eRqa_chip ._10eRqa_root ._10eRqa_deleteButton {
  vertical-align: text-bottom;
  display: inline-block;
}

._10eRqa_chip ._10eRqa_root ._10eRqa_deleteButton svg {
  width: 12px;
  height: 12px;
}

._10eRqa_chip ._10eRqa_root ._10eRqa_errorDeleteButton svg path {
  stroke: var(--color-white);
}

._10eRqa_chip ._10eRqa_errorRoot {
  background-color: var(--color-red-error);
}

._10eRqa_chip ._10eRqa_content {
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
}

._10eRqa_chip ._10eRqa_labelTextLimited {
  width: calc(100% - 16px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

._10eRqa_chip ._10eRqa_labelText {
  white-space: break-spaces;
}

._10eRqa_chip ._10eRqa_errorLabelText {
  color: var(--color-white);
}

.rpM4qG_radio {
  width: 100%;
  padding-right: var(--indent-xs) !important;
  margin: 0 !important;
}

.rpM4qG_radio svg circle {
  fill: var(--color-gray-800);
}

.rpM4qG_radio svg rect {
  stroke: var(--color-gray-800);
}

.rpM4qG_radioDisabled svg circle {
  fill: var(--color-gray-500);
}

.rpM4qG_radioDisabled svg rect {
  stroke: var(--color-gray-500);
}

.rpM4qG_label {
  padding: 0 var(--indent-xs);
}

.rpM4qG_label:hover {
  background-color: var(--color-blue-50);
  border-radius: var(--indent-xxs);
}

.rpM4qG_radioPlacement-start {
  align-self: flex-start;
}

.rpM4qG_radioPlacement-center {
  align-self: center;
}

.rpM4qG_radioPlacement-end {
  align-self: flex-end;
}

.mdnZKq_textFiled {
  width: 100%;
}

.mdnZKq_input {
  flex-grow: 1;
  max-width: 100% !important;
  min-width: 20px !important;
  width: unset !important;
  padding: 0 !important;
  font-size: 14px !important;
}

.mdnZKq_hidden {
  display: none !important;
}

.mdnZKq_placeholder {
  width: 100% !important;
}

.mdnZKq_inputRoot {
  gap: var(--indent-xxs);
  max-width: 100%;
  min-height: 34px;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  border-radius: var(--indent-xxs);
  max-height: 166px;
  flex-wrap: wrap;
  display: flex;
  overflow-y: auto;
  padding: var(--indent-xs) !important;
}

.mdnZKq_inputRoot::-webkit-scrollbar {
  width: 5px;
  background-color: var(--color-gray-500);
  border-radius: var(--indent-xxs);
}

.mdnZKq_inputRoot::-webkit-scrollbar-thumb {
  background: var(--color-gray-700);
  border-radius: 13px;
}

.mdnZKq_inputRoot:active, .mdnZKq_inputFocused {
  border: 1px solid var(--color-blue-500);
  box-shadow: 0 0 5px #2369f14f;
}

.mdnZKq_inputRoot:hover {
  border: 1px solid var(--color-blue-200);
}

.mdnZKq_inputRoot div {
  gap: var(--indent-xxs);
  flex-wrap: wrap;
  display: flex;
  position: relative;
}

.mdnZKq_inputRoot fieldset {
  border: none;
}

.mdnZKq_loader {
  height: 26px;
  width: 100px;
  position: relative;
}

.mdnZKq_popper {
  border-radius: 4px;
  box-shadow: 0 3px 7px #24306733;
}

.mdnZKq_inputRootAutocomplete {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  border-radius: var(--indent-xxs);
  overflow-y: auto;
}

.mdnZKq_inputRootAutocomplete:not(.mdnZKq_error):active, .mdnZKq_inputFocusedAutocomplete:not(.mdnZKq_error) {
  border: 1px solid var(--color-blue-500);
  box-shadow: 0 0 5px #2369f14f;
}

.mdnZKq_inputRootAutocomplete:not(.mdnZKq_error):hover {
  border: 1px solid var(--color-blue-200);
}

.mdnZKq_inputRootAutocomplete div {
  gap: var(--indent-xxs);
  flex-wrap: wrap;
  display: flex;
  position: relative;
}

.mdnZKq_inputRootAutocomplete > div > div {
  padding-top: var(--indent-xxs) !important;
  padding-bottom: var(--indent-xxs) !important;
}

.mdnZKq_inputRootAutocomplete fieldset {
  border: none;
}

.mdnZKq_error {
  border-color: var(--color-red-error);
}

.mdnZKq_errorText {
  color: var(--color-red-error);
}

.mdnZKq_inputAutocomplete {
  min-width: 120px !important;
  padding: var(--indent-xxs) !important;
  font-size: 14px !important;
}

.mdnZKq_option {
  margin: 0 var(--indent-xs);
  cursor: pointer;
}

.mdnZKq_optionContent {
  padding: var(--indent-xs);
  margin: 0;
}

.mdnZKq_optionContent:hover {
  background-color: var(--color-alpha-blue-50);
  border-radius: var(--indent-xxs);
}

.LpqQHW_container {
  align-items: center;
  column-gap: var(--indent-xs);
  display: flex;
}

.LpqQHW_link, .LpqQHW_link:visited {
  color: var(--main-blue);
}

.LpqQHW_link svg path {
  stroke: var(--main-blue);
}

.c4iLzG_root {
  z-index: 1299;
  max-height: 400px;
  min-width: 280px;
  max-width: 280px;
  background: var(--color-white);
  border-radius: 8px;
  padding: 24px;
  position: fixed;
  bottom: 42px;
  right: 42px;
  overflow: hidden;
  box-shadow: 0 8px 24px #1e222e1f, 0 4px 8px #1e222e0a;
}

.c4iLzG_closeBtn {
  right: var(--indent-md);
  top: var(--indent-md);
  position: absolute;
}

.c4iLzG_header {
  box-sizing: border-box;
}

.c4iLzG_header .c4iLzG_headerText {
  color: var(--color-gray-900);
  max-width: 100%;
}

.c4iLzG_footer {
  padding: 0 var(--indent-md);
  align-items: center;
  column-gap: var(--indent-s);
  row-gap: var(--indent-s);
  flex-wrap: wrap;
  display: flex;
}

.c4iLzG_footer.c4iLzG_right {
  justify-content: right;
}

.c4iLzG_footer.c4iLzG_center {
  justify-content: center;
}

.c4iLzG_footer.c4iLzG_left {
  justify-content: left;
}

.c4iLzG_footer.c4iLzG_space-between {
  justify-content: space-between;
}

.c4iLzG_content {
  box-sizing: border-box;
  padding: 0 var(--indent-md);
  width: 100%;
  color: var(--color-gray-900);
  overflow-x: hidden;
  overflow-y: auto;
}

.c4iLzG_content::-webkit-scrollbar {
  width: 8px;
  background-color: var(--color-gray-200);
}

.c4iLzG_content::-webkit-scrollbar-thumb {
  background: #0c236233;
  border-radius: 13px;
}

.c4iLzG_actions {
  align-items: center;
  gap: var(--indent-s);
  display: flex;
}

.qEx7hG_container, .qEx7hG_container .qEx7hG_multiSelect {
  width: 100%;
}

.qEx7hG_container .qEx7hG_multiSelect.qEx7hG_error .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--color-red-error);
}

.qEx7hG_container .qEx7hG_multiSelect .MuiInputBase-root {
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
}

.qEx7hG_container .qEx7hG_multiSelect .MuiInputBase-root .MuiInputBase-input {
  padding: var(--indent-xs) 44px var(--indent-xs) var(--indent-m);
  min-height: 20px;
  align-items: center;
  display: flex;
}

.qEx7hG_container .qEx7hG_multiSelect .MuiInputBase-root .qEx7hG_inputText {
  color: var(--color-gray-900);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.qEx7hG_container .qEx7hG_multiSelect .MuiInputBase-root .MuiSelect-icon {
  width: 34px;
  height: 100%;
  border-left: 1px solid var(--color-gray-500);
  padding-right: var(--indent-xxxs);
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0;
  right: 0;
}

.qEx7hG_container .qEx7hG_multiSelect .MuiInputBase-root .MuiSelect-icon.MuiSelect-iconOpen {
  transform: rotate(180deg)scale(-1, 1);
}

.qEx7hG_container .qEx7hG_multiSelect .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--color-gray-500);
}

.qEx7hG_container .qEx7hG_multiSelect .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-blue-200);
}

.qEx7hG_container .qEx7hG_multiSelect .MuiInputBase-root:has(.MuiInputBase-input[aria-expanded="true"]) .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--color-blue-400);
  box-shadow: 0 0 5px #2369f14f;
}

.qEx7hG_container .qEx7hG_multiSelect .qEx7hG_arrow {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.qEx7hG_container .qEx7hG_multiSelect .qEx7hG_arrow:hover {
  background-color: #0000;
}

.qEx7hG_container .qEx7hG_errorMessage {
  margin-top: var(--indent-xxxs);
  color: var(--color-red-error);
}

.qEx7hG_menu .MuiMenu-paper {
  box-shadow: 0 6px 10px #767b9233;
}

.qEx7hG_menu .MuiMenu-paper .MuiList-root {
  padding: var(--indent-xs);
  gap: var(--indent-xxs);
  flex-direction: column;
  display: flex;
}

.qEx7hG_menu .MuiMenu-paper .MuiList-root .qEx7hG_menuItem {
  padding: var(--indent-xs);
  gap: var(--indent-xs);
  flex-direction: row;
  display: flex;
}

.qEx7hG_menu .MuiMenu-paper .MuiList-root .qEx7hG_menuItem:hover {
  background-color: var(--color-blue-50);
}

.qEx7hG_menu .MuiMenu-paper .MuiList-root .qEx7hG_menuItem.Mui-focusVisible {
  background-color: var(--color-white);
}

.qEx7hG_menu .MuiMenu-paper .MuiList-root .qEx7hG_menuItem.Mui-selected {
  background-color: inherit;
}

.qEx7hG_menu .MuiMenu-paper .MuiList-root .qEx7hG_menuItem.Mui-selected:hover {
  background-color: var(--color-blue-50);
}

.s3MA-q_badge {
  background: var(--color-additionaly-cardinal);
  color: var(--color-white);
  height: 18px;
  min-width: 18px;
  padding: var(--indent-xxxs) var(--indent-xxs);
  border-radius: 18px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-size: 10px;
  font-weight: 600;
  display: flex;
}

.lo1ydW_root {
  width: 100%;
}

.lo1ydW_root .MuiInputBase-root {
  color: inherit;
  font: inherit;
  padding: var(--indent-xs) var(--indent-m);
}

.lo1ydW_root .MuiInputBase-root input {
  color: inherit;
  font: inherit;
  padding: var(--indent-xs) var(--indent-m);
  height: auto;
  line-height: 18px;
}

.lo1ydW_root .MuiInputBase-root input::placeholder {
  color: var(--color-gray-600);
  opacity: 1;
}

.lo1ydW_root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-gray-500);
}

.lo1ydW_root .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-blue-200);
}

.lo1ydW_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused > fieldset {
  border: 1px solid var(--color-blue-500);
  border-radius: 4px;
  box-shadow: 0 0 5px #2369f14f;
}

.lo1ydW_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  background-color: var(--color-gray-300);
}

.lo1ydW_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > fieldset {
  color: var(--color-gray-700);
  border: 1px solid #0000;
  border-radius: 4px;
}

.lo1ydW_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > input {
  -webkit-text-fill-color: var(--color-gray-700);
}

.lo1ydW_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled > input::placeholder {
  color: var(--color-gray-600);
}

.lo1ydW_root .MuiInputBase-root.MuiOutlinedInput-root.Mui-error > fieldset {
  border: 1px solid var(--color-red-error);
  border-radius: 4px;
}

.lo1ydW_root ::-webkit-scrollbar {
  width: 5px;
}

.lo1ydW_counter {
  color: var(--color-gray-800);
}

.lo1ydW_label {
  margin-bottom: 0;
}

.lo1ydW_textarea {
  flex-direction: column;
  display: flex;
}

.oH1WNq_formContainer {
  gap: var(--indent-s);
  padding: var(--indent-ml);
  border: 1px solid var(--color-gray-300);
  background-color: var(--color-white);
  border-radius: 4px;
  flex-direction: column;
  display: flex;
}

.CI4Eia_root {
  border-color: var(--color-gray-500);
}

.CI4Eia_root.CI4Eia_horizontal-no, .CI4Eia_root.CI4Eia_vertical-no {
  margin: 0;
}

.CI4Eia_root.CI4Eia_horizontal-xxs {
  margin: var(--indent-xxs) 0;
}

.CI4Eia_root.CI4Eia_vertical-xxs {
  margin: 0 var(--indent-xxs);
}

.CI4Eia_root.CI4Eia_horizontal-xs {
  margin: var(--indent-xs) 0;
}

.CI4Eia_root.CI4Eia_vertical-xs {
  margin: 0 var(--indent-xs);
}

.CI4Eia_root.CI4Eia_horizontal-s {
  margin: var(--indent-s) 0;
}

.CI4Eia_root.CI4Eia_vertical-s {
  margin: 0 var(--indent-s);
}

.CI4Eia_root.CI4Eia_horizontal-m {
  margin: var(--indent-m) 0;
}

.CI4Eia_root.CI4Eia_vertical-m {
  margin: 0 var(--indent-m);
}

.CI4Eia_root.CI4Eia_horizontal-l {
  margin: var(--indent-ml) 0;
}

.CI4Eia_root.CI4Eia_vertical-l {
  margin: 0 var(--indent-ml);
}

.vR1N_a_select {
  color: #252b49;
  width: 100%;
  font-size: 14px;
  line-height: 150%;
}

.vR1N_a_select .dbs__value-container {
  cursor: pointer;
}

.vR1N_a_select .dbs__control {
  box-shadow: none;
  min-height: 34px;
  border: 1px solid #fff;
}

.vR1N_a_select .vR1N_a_focused .dbs__control, .vR1N_a_select:hover .dbs__control {
  border: 1px solid #ebeef4 !important;
}

.vR1N_a_select .vR1N_a_focused .dbs__control .dbs__indicators, .vR1N_a_select:hover .dbs__indicators {
  visibility: visible;
}

.vR1N_a_select .dbs__indicators {
  visibility: hidden;
  height: 34px;
  border: none;
}

.vR1N_a_select .dbs__placeholder {
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  color: #767b92 !important;
}

.vR1N_a_select .vR1N_a_focused .dbs__placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 34px;
  color: #c6cae3 !important;
}

.vR1N_a_select .Select--multi .Select-input {
  display: inline-block !important;
}

.vR1N_a_select:hover .dbs__control {
  background-color: #f2f4f8;
}

.vR1N_a_select:focus .dbs__control {
  background-color: inherit;
}

.GGSHqq_chip {
  word-break: break-word;
  background-color: #1c50de;
  border-radius: 4px;
  align-items: center;
  margin: 2px;
  padding: 0 6px;
  display: flex;
}

.GGSHqq_editor {
  color: #252b49;
  outline: none;
}

.GGSHqq_icon {
  cursor: pointer;
  height: 18px;
  padding-left: 4px;
}

.GGSHqq_icon path {
  fill: #767b92;
}

.GGSHqq_dbs__input-container {
  overflow: hidden;
}

.GGSHqq_select {
  color: #252b49;
  width: 100%;
  font-size: 14px;
  line-height: 150%;
}

.GGSHqq_select .dbs__value-container {
  cursor: pointer;
}

.GGSHqq_select .dbs__control {
  box-shadow: none;
  min-height: 34px;
  border: 1px solid #fff;
}

.GGSHqq_select.GGSHqq_focused .dbs__control, .GGSHqq_select:hover .dbs__control {
  border: 1px solid #ebeef4 !important;
}

.GGSHqq_select.GGSHqq_focused .dbs__control .dbs__indicators, .GGSHqq_select:hover .dbs__control .dbs__indicators {
  visibility: visible;
}

.GGSHqq_select .dbs__indicators {
  visibility: hidden;
  height: 34px;
  border: none;
}

.GGSHqq_select .dbs__placeholder {
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  color: #767b92 !important;
}

.GGSHqq_select.GGSHqq_focused .dbs__placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 34px;
  color: #c6cae3 !important;
}

.GGSHqq_select .Select--multi .Select-input {
  display: inline-block !important;
}

.GGSHqq_select:hover .dbs__control {
  background-color: #f2f4f8;
}

.GGSHqq_select:focus .dbs__control {
  background-color: inherit;
}

.aIps6q_select {
  color: #252b49;
  width: 100%;
  font-size: 14px;
  line-height: 150%;
}

.aIps6q_select .dbs__control {
  box-shadow: none;
  border: 1px solid #ebeef4;
}

.aIps6q_select:hover .dbs__control {
  border: 1px solid #ebeef4;
}

.aIps6q_select .dbs__indicators {
  visibility: hidden;
  height: 34px;
  border: none;
}

.aIps6q_select .aIps6q_readable {
  display: block;
}

.aIps6q_select .aIps6q_editable {
  display: none;
}

.aIps6q_select:not(:disabled):focus .aIps6q_editable {
  display: block;
}

.aIps6q_select:not(:disabled):focus .aIps6q_readable {
  display: none;
}

