:root {
  --color-blue-900: #0c2362;
  --color-blue-800: #113187;
  --color-blue-700: #1a3fa3;
  --color-blue-600: #1848c9;
  --color-blue-500: #1c50de;
  --color-blue-400: #466fe0;
  --color-blue-300: #658aef;
  --color-blue-200: #a7beff;
  --color-blue-100: #d4e0ff;
  --color-blue-50: #f3f6ff;
  --color-gray-900: #212229;
  --color-gray-800: #5b5f73;
  --color-gray-700: #7f869e;
  --color-gray-600: #9da2b4;
  --color-gray-500: #b9bfcf;
  --color-gray-400: #dce1ee;
  --color-gray-300: #ebf0f9;
  --color-gray-200: #f6f7fa;
  --color-gray-100: #fafafc;
  --color-white: #fff;
  --color-black: #000;
  --color-border-chat: #ebeef4;
  --color-red-error: #dd1e2a;
  --color-additionaly-cardinal: #bc2041;
  --color-additionaly-bitter-sweet: #fd7c6e;
  --color-allert: #e9a33b;
  --color-purple-heart: #7c40b8;
  --color-alpha-gray-100: rgb(65 69 90 / 10%);
  --color-alpha-blue-50: rgb(17 22 147 / 6%);
  --secondary-yellow: #e1b411;
  --color-alpha-blue-100: rgb(12 35 98 / 10%);
  --color-secondary-green: #2ba13e;
  --color-aplha-dark-gray-900: rgb(65 69 90 / 90%);
  --color-additional-lemon: #f1d367;
  --color-yellowish-green: #87c159;
  --color-additional-light-turquoise: #2ad2d2;
}
