.button-icon {
  padding: 0;
  border: 0;
  line-height: 1;
  background-color: transparent;
  display: inline-flex;
  outline: none;
  z-index: 2;
}

.button-icon__img {
  display: inline-flex;
  user-select: none;
  pointer-events: none;
}

.button-icon.disabled {
  cursor: default;
  opacity: 0.5;
}

.button-icon:not(.disabled):hover .cws-icon-hover {
  fill-opacity: 15%;
}

/* Color */

/* gray */
.button-icon_color_gray .cws-icon-hover {
  fill: #767b92;
}

.button-icon_color_gray .cws-icon-main-fill {
  fill: #767b92;
}

.button-icon_color_gray .cws-icon-main-stroke {
  stroke: #767b92;
}

.button-icon_color_gray:not(.disabled):hover .cws-icon-main-fill {
  fill: #252b49;
}

.button-icon_color_gray:not(.disabled):hover .cws-icon-main-stroke {
  stroke: #252b49;
}

.button-icon_color_gray.disabled .cws-icon-main-fill {
  fill: #c6cae3;
}

/* red */
.button-icon_color_red .cws-icon-hover {
  fill: #fd4f53;
}

.button-icon_color_red .cws-icon-main-fill {
  fill: #fd4f53;
}

.button-icon_color_red .cws-icon-main-stroke {
  stroke: #fd4f53;
}

.button-icon_color_red:not(.disabled):hover .cws-icon-main-fill {
  fill: #fd4f53;
}

.button-icon_color_red:not(.disabled):hover .cws-icon-main-stroke {
  stroke: #fd4f53;
}

/* white */
.button-icon_color_white .cws-icon-hover {
  fill: rgb(37 43 73 / 40%);
}

.button-icon_color_white .cws-icon-main-fill {
  fill: #fff;
}

.button-icon_color_white .cws-icon-main-stroke {
  stroke: #fff;
}

.button-icon_color_white:not(.disabled):hover .cws-icon-main-fill {
  fill: #fff;
}

.button-icon_color_white:not(.disabled):hover .cws-icon-main-stroke {
  stroke: #fff;
}

.button-icon_color_white.disabled .cws-icon-main-fill {
  fill: #c6cae3;
}

.button-icon_color_white.disabled .cws-icon-main-stroke {
  stroke: #c6cae3;
}

/* blue */
.button-icon_color_blue .cws-icon-hover {
  fill: #1c50de;
}

.button-icon_color_blue .cws-icon-main-fill {
  fill: #1c50de;
}

.button-icon_color_blue .cws-icon-main-stroke {
  stroke: #1c50de;
}

.button-icon_color_blue .cws-icon-main-circle-fill {
  fill: #ebf0ff;
}

.button-icon_color_blue .cws-icon-main-circle-stroke {
  stroke: #ebf0ff;
}

.button-icon_color_blue:not(.disabled):hover .cws-icon-main-fill {
  fill: #1c50de;
}

.button-icon_color_blue:not(.disabled):hover .cws-icon-main-stroke {
  stroke: #1c50de;
}

.button-icon.button-icon_color_blue:not(.disabled):hover .cws-icon-hover {
  fill-opacity: 100%;
  fill: #ebf0ff;
}

/* Active color */

/* blue */
.button-icon.active.button-icon_active-color_blue .cws-icon-hover {
  fill: #1c50de;
}

.button-icon.active.button-icon_active-color_blue .cws-icon-main-fill {
  fill: #1c50de;
}

.button-icon.active.button-icon_color_gray .cws-icon-main-stroke {
  stroke: #1c50de;
}
