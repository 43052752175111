.cws-select {
}

.cws-select__title {
  display: flex;
  align-items: center;
  column-gap: var(--padding-xs);
  color: #252b49;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  white-space: nowrap;
}
