:root {
  --dark-blue: var(--color-blue-900);
  --main-blue: var(--color-blue-500);
  --lightest-blue: var(--color-blue-100);
  --secondary-green: #28aa5c;
  --main-dark-gray: var(--color-gray-900);
  --secondary-dark-gray: var(--color-gray-800);
  --secondary-middle-gray: var(--color-gray-700);
  --color-disabled: var(--color-gray-600);
  --divider-color: var(--color-gray-300);
  --hover-color: var(--color-gray-200);
  --bg-light-gray: var(--color-gray-100);
  --bg-blue: var(--color-blue-200);
  --bg-middle-blue: var(--color-blue-100);
  --bg-light-blue: var(--color-blue-50);
  --bg-msg-color: var(--bg-light-blue);
  --danger-color: #dd1e2a;
  --bg-content: #fff;
  --button-border-radius: 20px;

  /* Primary button */
  --bg-button-primary: var(--color-blue-500);
  --bg-button-primary-hover: var(--color-blue-600);
  --bg-button-primary-active: var(--color-blue-700);
  --bg-button-primary-disabled: var(--color-gray-600);
  --text-color-button-primary: #fff;
  --text-color-button-primary-hover: #fff;
  --text-color-button-primary-active: #fff;
  --text-color-button-primary-disabled: #fff;

  /* Outline button */
  --border-color-button-outline: var(--color-gray-800);
  --border-color-button-outline-hover: var(--color-blue-600);
  --border-color-button-outline-active: var(--color-blue-700);
  --border-color-button-outline-disabled: var(--color-gray-600);
  --bg-button-outline: #fff;
  --bg-button-outline-hover: #fff;
  --bg-button-outline-active: #fff;
  --bg-button-outline-disabled: #fff;
  --text-color-button-outline: var(--color-gray-800);
  --text-color-button-outline-hover: var(--color-blue-600);
  --text-color-button-outline-active: var(--color-blue-700);
  --text-color-button-outline-disabled: var(--color-gray-600);

  /* Text button */
  --text-color-button-text: var(--color-gray-800);
  --text-color-button-text-hover: var(--color-blue-600);
  --text-color-button-text-active: var(--color-blue-700);
  --text-color-button-text-disabled: var(--color-gray-600);

  /* Toast */
  --bg-toast-color: var(--color-aplha-dark-gray-900);
}
