.cws-dropdown-menu {
  position: relative;
  display: flex;
}

.cws-dropdown-menu__title {
  display: inline-flex;
  color: var(--color-gray-900);
}

.cws-dropdown-menu__list {
  padding: 8px 0;
  position: absolute;
  background: var(--color-white);
  box-shadow: 0 3px 7px rgb(36 48 103 / 20%);
  border-radius: 4px;
  z-index: 1040;
}

.cws-dropdown-menu__list.open {
  display: block;
}

.cws-dropdown-menu__list.close {
  display: none;
}

.cws-dropdown-menu__list.bottom {
  top: 34px;
}

.cws-dropdown-menu__list.top {
  top: -62px;
}

.cws-dropdown-menu-separator {
  margin: 8px 0;
  height: 1px;
  width: 100%;
  background-color: #f2f4f8;
}

.cws-dropdown-menu-item {
  color: var(--color-gray-900);
  display: flex;
  cursor: pointer;
  padding: 8px 24px 8px 10px;
  margin: 0 8px;
  align-items: center;
}

.cws-dropdown-menu-item > * {
  pointer-events: none;
}

.cws-dropdown-menu-item__helper {
  display: inline-flex;
  align-items: center;
  height: 14px;
  margin-left: 5px;
}

.cws-dropdown-menu-item .cws-dropdown-menu-item__helper {
  pointer-events: auto;
}

.cws-dropdown-menu-item .cws-dropdown-menu-item__selected {
  margin-left: 26px;
}

.cws-dropdown-menu-item:hover {
  background-color: var(--color-blue-50);
  border-radius: 2px;
}

.cws-dropdown-menu-item_disable {
  display: flex;
  cursor: default;
  padding: 8px 24px 8px 10px;
  align-items: center;
  color: #767b92;
}

.cws-dropdown-menu-item_tooltip {
  display: flex;
  align-items: center;
  gap: var(--indent-xs);
  padding-left: var(--indent-xs);
}

.cws-dropdown-menu-item_icon__disabled path {
  stroke: var(--color-gray-600);
}

.cws-dropdown-menu-item__icon {
  padding-right: 8px;
  display: inline-flex;
}

.cws-dropdown-menu-item__text {
  font-size: 14px;
  white-space: pre-wrap;
  line-height: 1;
}

/* color gray */
.cws-dropdown-menu-item_color_gray .cws-dropdown-menu-item__text {
  color: var(--color-gray-900);
}

.cws-dropdown-menu-item_color_gray .cws-icon-main-fill {
  fill: var(--color-gray-900);
}

.cws-dropdown-menu-item_color_gray .cws-icon-main-stroke {
  stroke: #767b92;
}

.cws-dropdown-menu-item_color_gray:hover .cws-dropdown-menu-item__text {
  color: var(--color-gray-900);
}

.cws-dropdown-menu-item_color_gray:hover .cws-icon-main-fill {
  fill: #252b49;
}

.cws-dropdown-menu-item_color_gray:hover .cws-icon-main-stroke {
  stroke: #252b49;
}

/* color red */
.cws-dropdown-menu-item_color_red .cws-dropdown-menu-item__text {
  color: #fd4f53;
}

.cws-dropdown-menu-item_color_red .cws-icon-main-fill {
  fill: #fd4f53;
}

.cws-dropdown-menu-item_color_red .cws-icon-main-stroke {
  stroke: #fd4f53;
}

/* color active */
.cws-dropdown-menu-item_color_active .cws-dropdown-menu-item__text {
  color: var(--color-gray-900);
}

/* color inactive */
.cws-dropdown-menu-item_color_inactive svg path {
  stroke: var(--color-gray-600);
}

.cws-dropdown-menu-item_color_inactive .cws-dropdown-menu-item__text {
  color: #c6cae3;
}

.cws-dropdown-menu-item_color_inactive .cws-icon-main-fill {
  fill: #c6cae3;
}

.cws-dropdown-menu-item_color_inactive .cws-icon-main-stroke {
  stroke: #c6cae3;
}

.cws-dropdown-menu-item_color_inactive:hover {
  background-color: unset;
  cursor: default;
}

.cws-dropdown-menu-item_color_inactive:hover .cws-dropdown-menu-item__text {
  color: #c6cae3;
}

.cws-dropdown-menu-item_color_inactive:hover .cws-icon-main-fill {
  fill: #c6cae3;
}

.cws-dropdown-menu-item_color_inactive:hover .cws-icon-main-stroke {
  stroke: #c6cae3;
}

.cws-dropdown-menu-item__arrow {
  margin-left: auto;
  padding-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: -10px;
}

.cws-dropdown-menu-item__arrow-img {
}

.cws-dropdown-menu-item .cws-dropdown-menu-item_color_inactive svg path {
  color: red;
}

.cws-dropdown-menu-item .cws-dropdown-menu-item_color_inactive svg path {
  color: red;
}
