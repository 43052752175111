:root {
  --video-chat-sidebar-width: 392px;
  --video-chat-control-panel-height: 81px;
}

.video-chat {
  background-color: var(--color-gray-900);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-chat.video-chat_close {
  visibility: hidden;
  z-index: -100;
}

.video-chat.video-chat_hide {
  visibility: hidden;
  z-index: -100;
}

.video-chat.video-chat_open {
  z-index: 1032;
}

.video-chat__section-video {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  font-weight: 700;
}

.video-chat__section_xl {
  width: 982px;
  height: 551px;
}

.video-chat__section_l {
  width: 1040px;
  height: 592px;
}

.video-chat__section_m {
  width: 1210px;
  height: 460px;
}

.video-chat__section_s_opt1 {
  width: 1280px;
  height: 344px;
}

.video-chat__section_s_opt2 {
  width: 895px;
  height: 526px;
}

.video-chat__section_s_opt3 {
  width: 1280px;
  height: 526px;
}

.video-chat__section-buttons {
  height: var(--video-chat-control-panel-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-gray-900);
  padding: 0 34px 34px;
}

.video-chat__section-buttons-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.video-chat__section-top-panel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--color-gray-900);
  padding: 20px 54px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.video-chat__container-dimensions__xl {
  width: 982px;
  font-size: 48px;
}

.video-chat__container-dimensions__l {
  width: 510px;
  font-size: 32px;
}

.video-chat__container-dimensions__m {
  width: 390px;
  font-size: 24px;
}

.video-chat__container-dimensions__s {
  width: 285px;
  font-size: 18px;
}

.video-chat__container-extra-margin_left {
  margin-left: 100px;
}

.video-chat__container-extra-margin_right {
  margin-right: 100px;
}

.video-chat__video-container {
  aspect-ratio: 16 / 9;
  display: flex;
  position: relative;
  border-radius: 4px;
  background-color: var(--color-gray-800);
  font-weight: 700;
  color: var(--color-white);
}

.video-chat__video {
  width: 100%;
  height: 100%;
  min-height: 220px;
  transform: scale(-1, 1);
  border-radius: 4px;
}

/* Hide all media control elements */
.video-chat__video::-webkit-media-controls {
  display: none !important;
}

.video-chat__background {
  background-color: var(--color-gray-800);
  border-radius: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  visibility: hidden;
}

.video-chat__background.show {
  visibility: visible;
}

.video-char__background_avatar__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.video-chat__background_avatar {
  font-size: inherit;
  font-weight: inherit;
  width: 22%;
  border-radius: 50%;
  background-color: var(--color-additionaly-lavender);
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-chat__label {
  position: absolute;
  bottom: 16px;
  left: 24px;
  color: var(--color-white);
  z-index: 3;
}

.video-chat__mic {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 20px;
  right: 20px;
  color: var(--color-white);
  z-index: 3;
}

.video-chat__btn {
  border: none;
  background: transparent;
  padding: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-chat__btn-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.video-chat__btn-transcript {
  background: #939393;
}

.video-chat__btn-transcript path {
  fill: #fff;
}

.video-chat__btn-mic-on {
  background: #939393;
}

.video-chat__btn-mic-off {
  background: #fd4646;
}

.video-chat__btn-videocam-on {
  background: #939393;
}

.video-chat__btn-videocam-off {
  background: #fd4646;
}

.video-chat__btn-mic-on .video-chat__btn-img_mic_off {
  opacity: 0;
}

.video-chat__btn-mic-off .video-chat__btn-img_mic_on {
  opacity: 0;
}

.video-chat__btn-videocam-on .video-chat__btn-img_videocam_off {
  opacity: 0;
}

.video-chat__btn-videocam-off .video-chat__btn-img_videocam_on {
  opacity: 0;
}

.video-chat__btn-call-end {
  width: 60px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--color-red-error);
}

.video-chat__btn-hide-call {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-gray-900);
}

.video-chat__btn-img_call_end,
.video-chat__btn-img_hide_call {
  width: 16px;
  height: 16px;
}

.video-chat__extra-members {
  color: var(--color-white);
}

.video-chat__extra-members_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
