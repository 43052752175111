.transcript-chat-header {
  display: flex;
  justify-content: space-between;
  column-gap: var(--padding-m);
  padding: var(--padding-xs);
  width: 100%;
}

.transcript-chat-header__title {
}

.transcript-chat-header__actions {
}
